import { Typography, Button } from "antd";
import { FC, useEffect, useState } from "react";
import { FlexCol, FlexRow } from "../common";
import { navigatorSupportsSerial } from "../serial/utils";
import { SwapOutlined } from "@ant-design/icons";
import { useMessageContext } from "../reducers/messageContext";
import { PairAndConnectivityWidget } from "../widgets/pairAndConnectivityWidget";
import { csmSerialPortExists, requestCsmSerialPort } from "../serial/devices/csmSerialDevice";

export const CsmSerialConnectPage: FC = () => {

    const {
        csmIsConnected,
    } = useMessageContext()

    const [ csmSerialIsPaired, setCsmSerialIsPaired ] = useState(false)

    useEffect(() => {
        csmSerialPortExists().then((exists) => {
            console.log('connect page: csmSerial exists: ', exists)
            setCsmSerialIsPaired(exists)
        })
    }, [])


    if (!navigatorSupportsSerial()) {
        return <FlexCol style={{
            justifyContent: "center",
            alignItems: "center",
            width: '100%',
            height: '100%',
            gap: 0,
        }}>
            <Typography.Title level={3}>Your browser does not support Web Serial</Typography.Title>
            <Typography.Text>Please use <b>Google Chrome</b>, <b>Microsoft Edge</b> or <b>Opera Browser</b></Typography.Text>
        </FlexCol>
    }

    

    return <FlexCol style={{
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        height: '100%',
        gap: 0,
    }}>
        <Typography.Title level={3} style={{
            marginBottom: 0
        }}>
            {csmIsConnected ?
                "Device connected" :
                "Device disconnected"
            }
        </Typography.Title>
        <Typography.Title level={5} style={{
            marginTop: 0,
            marginBottom: 30
        }}>{csmSerialIsPaired ?
            <>You can use the app</> :
            <>Connect CoreSensor Module <b>using a special USB cable</b> to start</>
        }</Typography.Title>
        <FlexRow style={{
            justifyContent: 'center',
        }}>
            <PairAndConnectivityWidget
                name="CoreSensor Module"
                isConnected={csmIsConnected}
                isPaired={csmSerialIsPaired}
                onPair={async () => {
                    try {
                        await requestCsmSerialPort()
                        setCsmSerialIsPaired(true)
                    } catch(e) {}
                }}
            />
        </FlexRow>
        <FlexCol style={{
            marginTop: 50,
            textAlign: 'center',
            maxWidth: "80%", 
        }}>
            <i>
                <span>Hint: If you are using the app for the <b>first time</b>, click on <SwapOutlined/> button to your PC to the device</span>
                <br/>
                <span>You should make sure that the device's <b>COM Port</b> is paired in the browser</span>
            </i>
        </FlexCol>
    </FlexCol>
}