import { NOA_SERIAL_HEADER } from "../constants/noaConstants";
import { byteArrayToInt2, byteArrayToInt4, intToByteArray2, intToByteArray4 } from "../utils";
import { crc16ccitt } from "crc";

const _readIntoViewWithTimeout = async (
    port: SerialPort,
    view: DataView,
    timeout: number
): Promise<[ArrayBuffer, number]> => {
    if (port.readable === null) {
        throw new Error("port not readable")
    }
    let reader = port.readable.getReader({mode: "byob"})
    let t = setTimeout(() => {
        reader.releaseLock()
    }, timeout)
    try {
        const { value } = await reader.read(
            view
        )
        clearTimeout(t)
        reader.releaseLock()
        if (value === undefined) {
            throw new Error('empty value')
        }
        return [ value.buffer, value.byteLength ]
    }
    catch(e: any) {
        if (e.message && e.message.includes("Releasing")) {
            throw new Error('timeout')
        }
        throw e
    }
}

const _readIntoViewBlocking = async (
    reader: ReadableStreamBYOBReader,
    view: DataView
): Promise<[ArrayBuffer, number]> => {
    try {
        const { value } = await reader.read(
            view
        );
        if (value === undefined) {
            throw new Error('empty value')
        }
        return [ value.buffer, value.byteLength ]
    }
    catch(e: any) {
        throw e
    }
}

const _readNBytesWithTimeout = async (port: SerialPort, n: number, timeout: number): Promise<Uint8Array> => {
    if (port.readable === null) {
        throw new Error("port not readable")
    }
    let offset = 0
    let buffer = new ArrayBuffer(n)
    while (offset < buffer.byteLength) {
        // console.log('reading into buffer', offset, buffer.byteLength)
        let view = new DataView(buffer, offset, buffer.byteLength - offset)
        try {
            let [ newBuffer, bytesRead ] = await _readIntoViewWithTimeout(port, view, timeout)
            buffer = newBuffer
            offset += bytesRead
        } catch(e: any) {
            if (e.message && e.message.includes("timeout")) {
                throw new Error(`timeout while trying to read ${n} bytes; only ${offset} bytes received`)
            }
            throw e
        }        
    }
    return new Uint8Array(buffer);
}

const _readNBytesBlocking = async (reader: ReadableStreamBYOBReader, n: number): Promise<Uint8Array> => {
    let offset = 0
    let buffer = new ArrayBuffer(n)
    while (offset < buffer.byteLength) {
        let view = new DataView(buffer, offset, buffer.byteLength - offset)
        let [ newBuffer, bytesRead ] = await _readIntoViewBlocking(reader, view)
        buffer = newBuffer
        offset += bytesRead        
    }
    return new Uint8Array(buffer);
}

export const readPayloadWithTimeout = async (port: SerialPort, lengthNBytes: number, timeout: number): Promise<Uint8Array> => {console.log
    console.debug('about to read payload with timeout', timeout)
    let headerAttempt = 1
    // create a sliding view of the last 4 bytes
    let headerBytesSlidingView = new Uint8Array(4)
    // read the first 3 bytes
    let headerBytes = await _readNBytesWithTimeout(port, 3, timeout)
    // put them in the sliding view in the last 3 positions
    headerBytesSlidingView.set(headerBytes, 1)
    while (true) {
        // read the next byte
        let headerByte = await _readNBytesWithTimeout(port, 1, timeout)
        // shift the sliding view to the left by 1
        headerBytesSlidingView.copyWithin(0, 1)
        // put the new byte in the last position
        headerBytesSlidingView.set(headerByte, 3)
        // check if the sliding view matches the header
        if (
            headerBytesSlidingView[0] !== NOA_SERIAL_HEADER[0] ||
            headerBytesSlidingView[1] !== NOA_SERIAL_HEADER[1] ||
            headerBytesSlidingView[2] !== NOA_SERIAL_HEADER[2] ||
            headerBytesSlidingView[3] !== NOA_SERIAL_HEADER[3]
        ) {
            console.log(`invalid header, attempt #${headerAttempt}, continuing..`)
            headerAttempt++
        } else {
            if (headerAttempt > 1) {
                console.log(`valid header after ${headerAttempt} attempts`)
            }
            break
        }
    }

    let lengthBytes = await _readNBytesWithTimeout(port, lengthNBytes, timeout)
    var length: number
    if (lengthNBytes === 2) {
        length = byteArrayToInt2(lengthBytes)
    } else if (lengthNBytes === 4) {
        length = byteArrayToInt4(lengthBytes)
    } else {
        throw new Error(`invalid lengthNBytes ${lengthNBytes}`)
    }
    // console.log('length bytes', lengthBytes, length)

    let payloadBytes = await _readNBytesWithTimeout(port, length, timeout)
    // console.log('payload bytes', payloadBytes)

    let crcBytes = await _readNBytesWithTimeout(port, 2, timeout)
    // console.log('crc bytes', crcBytes)
    let localCrc = crc16ccitt(payloadBytes)
    let remoteCrc = byteArrayToInt2(crcBytes)

    if (localCrc !== remoteCrc) {
        console.log(`Invalid CRC: local ${localCrc} [${intToByteArray2(localCrc)}] != remote ${remoteCrc} [${crcBytes}]`)
    }
    return payloadBytes
}

export const readPayloadBlocking = async (reader: ReadableStreamBYOBReader, lengthNBytes: number): Promise<Uint8Array> => {
    let headerAttempt = 1
    // create a sliding view of the last 4 bytes
    let headerBytesSlidingView = new Uint8Array(4)
    // read the first 3 bytes
    // Only this first request is blocking without timeout
    let headerBytes = await _readNBytesBlocking(reader, 3)
    // put them in the sliding view in the last 3 positions
    headerBytesSlidingView.set(headerBytes, 1)
    while (true) {
        // read the next byte
        let headerByte = await _readNBytesBlocking(reader, 1)
        // shift the sliding view to the left by 1
        headerBytesSlidingView.copyWithin(0, 1)
        // put the new byte in the last position
        headerBytesSlidingView.set(headerByte, 3)
        // check if the sliding view matches the header
        if (
            headerBytesSlidingView[0] !== NOA_SERIAL_HEADER[0] ||
            headerBytesSlidingView[1] !== NOA_SERIAL_HEADER[1] ||
            headerBytesSlidingView[2] !== NOA_SERIAL_HEADER[2] ||
            headerBytesSlidingView[3] !== NOA_SERIAL_HEADER[3]
        ) {
            console.log(`invalid header, attempt #${headerAttempt}, continuing..`)
            headerAttempt++
        } else {
            if (headerAttempt > 1) {
                console.log(`valid header after ${headerAttempt} attempts`)
            }
            break
        }
    }

    let lengthBytes = await _readNBytesBlocking(reader, lengthNBytes)
    var length: number
    if (lengthNBytes === 2) {
        length = byteArrayToInt2(lengthBytes)
    } else if (lengthNBytes === 4) {
        length = byteArrayToInt4(lengthBytes)
    } else {
        throw new Error(`invalid lengthNBytes ${lengthNBytes}`)
    }
    // console.log('length bytes', lengthBytes, length)

    let payloadBytes = await _readNBytesBlocking(reader, length)
    // console.log('payload bytes', payloadBytes)

    let crcBytes = await _readNBytesBlocking(reader, 2)
    // console.log('crc bytes', crcBytes)
    let localCrc = crc16ccitt(payloadBytes)
    let remoteCrc = byteArrayToInt2(crcBytes)

    if (localCrc !== remoteCrc) {
        throw new Error(`Invalid CRC: local ${localCrc} [${intToByteArray2(localCrc)}] != remote ${remoteCrc} [${crcBytes}]`)
    }
    return payloadBytes
}

export const composeTramNoa = (payload: Uint8Array, lengthNBytes: number): Uint8Array => {
    var lengthBytes: Uint8Array
    if (lengthNBytes === 2) {
        lengthBytes = intToByteArray2(payload.length)
    } else if (lengthNBytes === 4) {
        lengthBytes = intToByteArray4(payload.length)
    } else {
        throw new Error(`invalid lengthNBytes ${lengthNBytes}`)
    }
    let crc = crc16ccitt(payload)
    let crcBytes = intToByteArray2(crc)

    let buffer = new ArrayBuffer(NOA_SERIAL_HEADER.length + lengthBytes.length + payload.length + crcBytes.length)
    let tram = new Uint8Array(buffer)

    tram.set(NOA_SERIAL_HEADER, 0)
    tram.set(lengthBytes, NOA_SERIAL_HEADER.length)
    tram.set(payload, NOA_SERIAL_HEADER.length + lengthBytes.length)
    tram.set(crcBytes, NOA_SERIAL_HEADER.length + lengthBytes.length + payload.length)

    return tram
}