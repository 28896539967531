import { crc16ccitt } from "crc";

export enum CSM_PROTOCOL_EVENT_TYPE {
    ErrorEvent = 0xEE,
    HeartbeatEvent = 0xE0,
    BiosensorsSignalEvent = 0xE1,
    LifeCycleEvent = 0xE2,
}

export enum CSM_PROTOCOL_COMMAND_TYPE {
    DeviceReset = 0x01,
    GetVersions = 0x06,
    GetBiosensorSignalMap = 0x0A,
    StartSampling = 0x20,
    StopSampling = 0x21,
    SetReference = 0x22,
    SetBiosensorSerialNumberAndSignalMap = 0x45,
    GetBiosensorSerialNumber = 0x46,
    SetSamplingRate = 0x50,
    GetSamplingRate = 0x51,
}

export enum CSM_PROTOCOL_MISO_RESPONSE_STATUS {
    UnhandledCommand = 0x00,
    Successful = 0x01,
    WrongDataLength = 0x02,
    NoAccessRight = 0x03,
    IllegalCommandParameter = 0x04,
    NotAbleToExecute = 0x05,
    UnknownError = 0xFF,
}

export enum CSM_PROTOCOL_LIFECYCLE_EVENT_STATE {
    Initializing = 0x01,
    Calibrating =  0x02,
    Ready = 0x03,
    Acquiring = 0x04,
    InitializationError = 0xE0,
    CalibrationError = 0xE1,
}

export const CSM_PROTOCOL_START = 0x55
export const CSM_PROTOCOL_STOP = 0x0D


export interface CSMMisoFrame {
    StartFlag: number // 1 byte
    PeripheralAddress: number // 1 byte
    Type: number // 1 byte
    Payload: Uint8Array // n bytes
    LocalCRC: number // 2 bytes
    RemoteCRC: number // 2 bytes
    StopFlag: number // 1 byte
}

export interface CSMMosiCommand {
    CmdType: CSM_PROTOCOL_COMMAND_TYPE
    Payload?: Uint8Array
}

export interface CSMEventPayload {
    Counter: number // 1 byte
    Tick: number // 4 bytes
    Data: Uint8Array // n bytes
}

export interface CSMResponsePayload {
    Status: CSM_PROTOCOL_MISO_RESPONSE_STATUS
    Data: Uint8Array
}

export type CSMVersionsInfoMap = Record<string, string>

const destuffBytes = (src: Uint8Array): Uint8Array => {
    let dst = new Uint8Array(src.byteLength)
    var pos = 0;
    for (let i = 0; i < src.length; i++) {
        var b, bNext
        b = src[i];
        if (i < src.length - 1) bNext = src[i + 1];
        if (b == 0x7F) {
            if (bNext == 0xA5) {
                b = 0x55;
                i++;
            } else if (bNext == 0xAD) {
                b = 0x0D;
                i++;
            } else if (bNext == 0xAF) {
                b = 0x7F;
                i++;
            }
        }
        dst[pos] = b;
        pos++;
    }
    if (pos < dst.length) {
        // console.log("destuffBytes: pos < dst.length: ", pos, dst.length)
        dst = dst.slice(0, pos)
    }
    return dst.slice(0, pos)
}

const stuffBytes = (src: Uint8Array): Uint8Array => {
    let dst = new Uint8Array(src.byteLength * 2)
    var pos = 0;
    for (let i = 0; i < src.length; i++) {
        var b = src[i];
        if (b == 0x55) {
            dst[pos] = 0x7F;
            dst[pos + 1] = 0xA5;
            pos += 2;
        } else if (b == 0x0D) {
            dst[pos] = 0x7F;
            dst[pos + 1] = 0xAD;
            pos += 2;
        } else if (b == 0x7F) {
            dst[pos] = 0x7F;
            dst[pos + 1] = 0xAF;
            pos += 2;
        } else {
            dst[pos] = b;
            pos++;
        }
    }
    return dst.slice(0, pos)
}

export const parseMisoFrame = (frame: Uint8Array): CSMMisoFrame => {
    let frameView = new DataView(frame.buffer)
    let peripheralAddress = frameView.getUint8(0)
    let type = frameView.getUint8(1)
    let payload = new Uint8Array(frameView.buffer.slice(2, frameView.byteLength - 2))
    let remoteCrc = frameView.getUint16(frameView.byteLength - 2, true)

    let localCrcSrcBytes = frame.slice(0, frame.byteLength - 2)
    let localCrc = crc16ccitt(localCrcSrcBytes)
    localCrc = ((localCrc & 0xFF) << 8) | ((localCrc >> 8) & 0xFF)

    if (remoteCrc !== localCrc) {
        console.debug("parseMisoFrame: CRC mismatch: ", remoteCrc, localCrc)
    }

    return {
        StartFlag: 0,
        PeripheralAddress: peripheralAddress,
        Type: type,
        Payload: payload,
        LocalCRC: localCrc,
        RemoteCRC: remoteCrc,
        StopFlag: 0
    }
}

export const parseEventPayload = (payload: Uint8Array): CSMEventPayload => {
    let view = new DataView(payload.buffer)
    let counter = view.getUint8(0)
    let tick = view.getUint32(1, true)
    let data = new Uint8Array(payload.buffer.slice(5, payload.byteLength))

    return {
        Counter: counter,
        Tick: tick,
        Data: data
    }
}

export const parseResponsePayload = (payload: Uint8Array): CSMResponsePayload => {
    let status = payload[0]
    if (status !== 1) {
        let reason = new TextDecoder().decode(payload.slice(1, payload.byteLength))
        throw Error(`parseResponsePayload: status !== 1: ${status}; reason: ${reason}`)
    }
    return {
        Status: status,
        Data: payload.slice(1, payload.byteLength)
    }
}

export const parseBiosensorsSignalEvent = (data: Uint8Array) => {
    if (data.byteLength % 4 !== 0) {
        throw Error("parseBiosensorsSignalEvent: data.byteLength % 4 !== 0")
    }
    let view = new DataView(data.buffer)
    let float32s = []
    for (let i = 0; i < data.byteLength; i += 4) {
        float32s.push(view.getFloat32(i, true))
    }
    return float32s
}

export const truncateFourDigitalSpotsgrid1D = (spotsgrid1d: number[]): number[] => {
    let truncatedSpotsgrid1d: number[] = []
    for (let spotInt of spotsgrid1d) {
        let spotStr = spotInt.toString()
        if (spotStr.length === 4 && spotStr[3] === '4') {
            spotStr = spotStr.slice(0, 2) + "4"
        }
        if (spotStr === "114") {
            spotStr = "1"
        }
        let truncatedSpotInt = parseInt(spotStr)
        truncatedSpotsgrid1d.push(truncatedSpotInt)
    }
    return truncatedSpotsgrid1d
}

export const parseBiosensorSignalMapResponse = (data: Uint8Array): number[] => {
    
    if (data.byteLength % 2 !== 0) {
        throw Error("parseBiosensorSignalMap: data.byteLength % 2 !== 0")
    }
    let view = new DataView(data.buffer)
    let spotsgrid1d: number[] = []
    for (let i = 0; i < data.byteLength; i += 2) {
        let spotId = view.getInt16(i, true)
        spotsgrid1d.push(spotId)
    }
    return spotsgrid1d
}

export const encodeBiosensorSignalMap = (spotsgrid1d: number[]): Uint8Array => {
    let data = new Uint8Array(spotsgrid1d.length * 2)
    let view = new DataView(data.buffer)
    for (let i = 0; i < spotsgrid1d.length; i++) {
        view.setInt16(i * 2, spotsgrid1d[i], true)
    }
    return data
}

export const parseVersionsResponse = (data: Uint8Array): CSMVersionsInfoMap => {
    let str = new TextDecoder().decode(data)
    let keyValues = str.split(",")
    let versionsMap: CSMVersionsInfoMap = {}
    for (let keyValue of keyValues) {
        keyValue = keyValue.trim() // trim whitespaces
        keyValue = keyValue.replace(/\0/g, '') // trim null characters
        let keyValueSplit = keyValue.split(":")
        if (keyValueSplit.length !== 2) {
            throw Error(`parseVersionsResponse: keyValueSplit.length !== 2: ${keyValueSplit.length}`)
        }
        let key = keyValueSplit[0]
        let value = keyValueSplit[1]
        versionsMap[key] = value
    }
    return versionsMap
}

export const decodeMisoFrame = (uint8array: Uint8Array): CSMMisoFrame => {
    let destuffedArray = destuffBytes(uint8array)
    return parseMisoFrame(destuffedArray)
}

export const encodeMosiCommand = (cmdType: CSM_PROTOCOL_COMMAND_TYPE, payload?: Uint8Array): Uint8Array => {
    if (!payload || payload.byteLength === 0) {
        payload = new Uint8Array(1)
    }
    let payloadStuffed = stuffBytes(payload)
    let frame = new Uint8Array(3 + payloadStuffed.byteLength + 3)
    frame[0] = CSM_PROTOCOL_START
    frame[1] = 0x01 // peripheral address
    frame[2] = cmdType
    frame.set(payloadStuffed, 3)

    let localCrcSrcBytes = new Uint8Array(2 + payload.byteLength)
    localCrcSrcBytes[0] = 0x01 // peripheral address
    localCrcSrcBytes[1] = cmdType
    localCrcSrcBytes.set(payload, 2)
    // console.log(`encodeMosiCommand: localCrcSrcBytes: ${localCrcSrcBytes}`)

    let localCrc = crc16ccitt(localCrcSrcBytes)
    let crcBytesView = new DataView(new ArrayBuffer(2))
    crcBytesView.setUint16(0, localCrc)

    frame.set(new Uint8Array(crcBytesView.buffer), frame.byteLength - 3)
    frame[frame.byteLength - 1] = CSM_PROTOCOL_STOP
    return frame
}

export const encodeMosiSetBiosensorCommandData = (biosensorSerialNumber: string, spotsgrid1d: number[]): Uint8Array => {
    const stringBytes = new Uint8Array(32);
    for (let i = 0; i < biosensorSerialNumber.length && i < 32; i++) {
        stringBytes[i] = biosensorSerialNumber.charCodeAt(i) & 0xFF;
    }
    // Create a new Uint8Array with a length of 64*2 + 32 = 160
    const combinedArray = new Uint8Array(32 + 64 * 2)
    // Copy the string bytes into the first 32 positions of the combined array
    combinedArray.set(stringBytes.slice(0, 32))

    // Copy the encodedBiosensorSignalMap (int8Array) into the last 64 positions of the combined array
    let encodedBiosensorSignalMap = encodeBiosensorSignalMap(spotsgrid1d)
    combinedArray.set(encodedBiosensorSignalMap, 32)
    return combinedArray
}

export const csmMosiStartSampling = (): Uint8Array => {
    return encodeMosiCommand(CSM_PROTOCOL_COMMAND_TYPE.StartSampling)
}

export const csmMosiStopSampling = (): Uint8Array => {
    return encodeMosiCommand(CSM_PROTOCOL_COMMAND_TYPE.StopSampling)
}

export const csmMosiGetVersions = (): Uint8Array => {
    return encodeMosiCommand(CSM_PROTOCOL_COMMAND_TYPE.GetVersions)
}

export const csmMosiSetReference = (): Uint8Array => {
    return encodeMosiCommand(CSM_PROTOCOL_COMMAND_TYPE.SetReference)
}

export const csmMosiGetBiosensorSignalMap = (): Uint8Array => {
    return encodeMosiCommand(CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSignalMap)
}