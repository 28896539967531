import { isInsideEllipse } from "./ellipse";
import { pcaTransform } from "./pca";
import { euclideanDistance } from "./utils";

/*
Aryballe custom classificator
A point is a projection of a signature on a 2D space (PCA or DC+PCA)
If point is inside one of the optimized (scaled) ellipses -> classified as this group
If point outside any ellipse -> unknown
If point is inside >1 ellipses -> group assigned as least euclidean distance between these groups' barycenters
*/
export const classifyPoint = (groupedScaledEllipses: Record<string, number[]>, point: [number, number]): string => {
    const results: string[] = [];
    Object.entries(groupedScaledEllipses).forEach(([group, ellipse]) => {
        if (isInsideEllipse(ellipse, point)) {
            results.push(group);
        }
    });
    const centroids = Object.entries(groupedScaledEllipses).map(([group, [cx, cy, a, b, theta]]) => ({ group, centroid: [cx, cy]}));
    let name: string = 'unknown';
    if (results.length === 1) {
        name = results[0];
    } else if (results.length > 1) {
        const centroidDistances = centroids.map(({group, centroid}) => ({group, dist: euclideanDistance(point, centroid)}));

        var closestGroup: string = centroidDistances[0].group;
        var closestDistance: number = centroidDistances[0].dist;

        for (let i = 1; i < centroidDistances.length; i++) {
            let dist = centroidDistances[i].dist;
            if (dist < closestDistance) {
                closestDistance = dist;
                closestGroup = centroidDistances[i].group;
            }
        }
        name = closestGroup;
    }
    return name;
};

// signature should be of the same form as used in the model creation
// e.g. negative spots excluded, aggregated, sorted and normalized
//
// returns [group, point]
export const classifySignature = (
    groupedScaledEllipses: Record<string, number[]>,
    pcaEigenvectors: number[][],
    signature: number[]
): [string, [number, number]] => {
    const points = pcaTransform([signature], pcaEigenvectors, 2);
    let point: [number, number] = [points[0][0], points[0][1]];
    return [
        classifyPoint(groupedScaledEllipses, point),
        point
    ]
}