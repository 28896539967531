import { ControlOutlined, FolderOpenOutlined, FundViewOutlined } from "@ant-design/icons"
import { Card, Col, Row, Typography } from "antd"
import { Link } from "react-router-dom"
import { FlexCol } from "../common"
import { FC } from "react"
import { ApplicationDeviceType, getApplicationDeviceType, useMediaQuery } from "../utils"

const CardLink: FC<{
    to: string,
    title: string,
    icon: JSX.Element
}> = ({
    to,
    title,
    icon
}) => {
    return <Link to={to}>
    <Card style={{
        height: '100%',
        backgroundColor: "#1890FF10",
        padding: 5
    }} hoverable={true}>
        <FlexCol style={{
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            {icon}
            <h3 style={{ fontSize: 16, textAlign: 'center' }}>
                {title}
            </h3>
        </FlexCol>
    </Card>
</Link>
}

export const HomePage: FC = () => {
    
    const isMobile = useMediaQuery()
    const fontSize = 40

    return <FlexCol style={{
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: '100%',
    }}>
        <Row>
            <FlexCol style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Typography.Title level={2} style={{
                    fontSize,
                    marginBottom: 0,
                    textAlign: 'center'
                }}>
                    Welcome to NeOse WebApp
                </Typography.Title>
                <Typography.Text style={{
                    fontSize: 20,
                    textAlign: 'center'
                }}>{
                        function(){
                            switch(getApplicationDeviceType()) {
                                case ApplicationDeviceType.Noa:
                                    return <>Make sure to connect your <b>NeOse Advance</b> device to your computer and let's goooooo</>
                                case ApplicationDeviceType.CsmSerial:
                                    return <>Make sure to connect your <b>CoreSensor Module</b> to your computer and let's goooooo</>
                                case ApplicationDeviceType.CsmBle:
                                    return <>Make sure to turn on <span style={{color: '#0082FC'}}>Bluetooth</span> on your {isMobile ? "smartphone" : "computer"} and let's goooooo</>
                            }  
                        }()
                    }
                </Typography.Text>
            </FlexCol>
        </Row>
        <Row gutter={[10, 10]} align="stretch">
            <Col xs={24} md={8}>
                <CardLink
                    to={`/device/${getApplicationDeviceType()}`}
                    title="Manage my NeOse"
                    icon={<ControlOutlined style={{ fontSize }} />}
                />
            </Col>
            <Col xs={24} md={8}>
                <CardLink
                    to={`/sense/${getApplicationDeviceType()}`}
                    title="RealTime Olfactive Sensing"
                    icon={<FundViewOutlined style={{ fontSize }} />}
                />
            </Col>
            <Col xs={24} md={8}>
                <CardLink
                    to="/records"
                    title="Manage my Olfactive Records"
                    icon={<FolderOpenOutlined style={{ fontSize }} />}
                />
            </Col>
        </Row>
    </FlexCol>
}