import { FC, useEffect, useRef, useState } from 'react';
import { useMessageContext } from '../reducers/messageContext';
import { ConnectionIndicator } from '../widgets/connectionIndicator';
import { navigatorSupportsSerial } from '../serial/utils';
import { detectCsmSerialPort, readMsgBlocking, readMsgWithTimeout } from '../serial/devices/csmSerialDevice';
import { CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE, CSM_PROTOCOL_LIFECYCLE_EVENT_STATE, CSM_PROTOCOL_MISO_RESPONSE_STATUS, csmMosiGetVersions, decodeMisoFrame, encodeMosiCommand, parseBiosensorSignalMapResponse, parseEventPayload, parseResponsePayload, parseVersionsResponse } from '../serial/protocols/csmProtocol';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_BLOCKING_LOOP_UNEXPECTED_ERROR_WAIT, DEFAULT_DEVICE_DETECTION_INTERVAL, DEFAULT_READ_TIMEOUT, DEFAULT_WRITE_TIMEOUT } from '../serial/constants/commonConstants';
import { saveSpotsGrid1D } from '../localStorage';
import { writeWithTimeout } from '../serial/devices/commonSerialDevice';
import { MutexInterface, withTimeout, Mutex } from 'async-mutex';
import { message as antdMessage } from 'antd';

export const CsmSerialMessageDispatcher: FC<{
    minimized: boolean
}> = ({
    minimized
}) => {
    const { 
        csmMessages, addCSMMessage,
        csmCommands,
        csmSerialPort, setCSMSerialPort,
        csmIsConnected, setCSMIsConnected,
        consumeCSMCommand,
        addCSMCommand,
    } = useMessageContext();
    const writeMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 1000))

    // connection loop
    useEffect(() => {
        if (!navigatorSupportsSerial()) {
            return
        }
        // console.log("csmSerial infinite loop: checking csmSerialPort..")
        if (csmSerialPort === undefined || csmSerialPort.readable === null || csmSerialPort.writable === null) {
            console.log("csmSerial infinite loop: cannot start because port is undefined", csmSerialPort)
            setCSMIsConnected(false)
            return
        }
        // console.log("csmSerial infinite loop: start")
        setCSMIsConnected(true)

        var reader: ReadableStreamDefaultReader<Uint8Array> | null = null
        
        const loop = async () => {
            if (csmSerialPort === undefined || csmSerialPort.readable === null || csmSerialPort.writable === null) {
                setCSMIsConnected(false)
                return
            }
            reader = csmSerialPort.readable.getReader()
            while (true) {
                try {
                    // let t0 = Date.now()
                    let v = await readMsgBlocking(reader)
                    // console.log(`csmSerial infinite loop: readMsgBlocking dt=${Date.now() - t0} ms; len=${v.byteLength}`)
                    if (v === null || v === undefined || v.byteLength === 0) {
                        console.log('csmSerial infinite loop: readMsgBlocking returned empty buffer')
                        continue
                    }
                    let frame = decodeMisoFrame(v)
                    if (frame.LocalCRC !== frame.RemoteCRC) {
                        continue
                    }
                    addCSMMessage({
                        id: uuidv4().toString(),
                        ts: Date.now(),
                        message: frame
                    })
                    if (frame.Type < 0x80) {
                        // miso responses
                        console.log('miso response', frame)
                        try {
                            parseResponsePayload(frame.Payload)
                        } catch(e: any) {
                            console.warn(e.message)
                        }
                        if (frame.Type === CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSignalMap) {
                            // console.log("got biosensor signal map", frame.Payload)
                            try {
                                let responsePayload = parseResponsePayload(frame.Payload)
                                let spotsgrid1d = parseBiosensorSignalMapResponse(responsePayload.Data)
                                // console.debug('biosensor signal map', spotsgrid1d)
                                saveSpotsGrid1D(spotsgrid1d)
                            } catch(e: any) {
                                console.log('error parsing biosensor signal map response', e.message)
                            }
                        }
                    } else {
                        // miso events
                        // try {
                        //     let event = parseEventPayload(frame.Payload)
                        //     console.log('miso event', event.Counter, event.Tick)
                        // } catch {
                        // }
                        switch (frame.Type) {
                            case CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent:
                                addCSMMessage({
                                    id: uuidv4().toString(),
                                    ts: Date.now(),
                                    message: frame
                                })
                                break
                            case CSM_PROTOCOL_EVENT_TYPE.ErrorEvent:
                                let errorEvent = parseEventPayload(frame.Payload)
                                console.log('csm protocol error: ', new TextDecoder().decode(errorEvent.Data))
                                break
                            case CSM_PROTOCOL_EVENT_TYPE.HeartbeatEvent:
                                let hbEvent = parseEventPayload(frame.Payload)
                                // console.debug('csm protocol heartbeat: ', event.Counter)
                                break
                            case CSM_PROTOCOL_EVENT_TYPE.LifeCycleEvent:
                                let lcEvent = parseEventPayload(frame.Payload)
                                let lcState = lcEvent.Data[0] as CSM_PROTOCOL_LIFECYCLE_EVENT_STATE
                                let lcDataByte = lcEvent.Data[0]
                                if (lcDataByte === undefined) {
                                    break
                                }
                                let lcStateString = CSM_PROTOCOL_LIFECYCLE_EVENT_STATE[lcDataByte]
                                console.log('csm protocol lifecycle: ', lcEvent, lcStateString)
                                switch(lcState) {
                                    case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Initializing:
                                    case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Calibrating:
                                        antdMessage.info(`CSM is ${lcStateString}..`)
                                        break
                                    case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Ready:
                                    case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Acquiring:
                                        antdMessage.success(`CSM is ${lcStateString}!`)
                                        break
                                    case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.CalibrationError:
                                    case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.InitializationError:
                                        antdMessage.error(`CSM LifeCycle ${lcStateString} 😿! You might need to unplug and replug the sensor..`)
                                        break
                                }
                                break
                            default:
                                console.log('csm protocol unknown: ', frame.Type, frame.Payload)
                        }
                    }
                } catch (e: any) {
                    if (e && e.message) {
                        if (e.message.includes('Buffer overrun')) {
                            console.log(`csmSerial infinite loop: buffer overrun; ${e.message}`)
                            break
                        }
                        if (e.message.includes('has been released') || e.message.includes('Releasing')) {
                            console.log("csmSerial port reader has been released; breaking..")
                            break
                        }
                        if (e.message.includes('device has been lost')) {
                            console.log(e.message)
                            break
                        }
                        if (e.message.includes('Invalid CRC')) {
                            // console.debug(e.message)
                            continue
                        }
                        if (e.message.includes('Offset is outside the bounds of the DataView')) {
                            console.log(e.message)
                            continue
                        }
                    }
                    console.log("csmSerial infinite loop unexpected error:", e)
                    await new Promise((resolve) => setTimeout(resolve, DEFAULT_BLOCKING_LOOP_UNEXPECTED_ERROR_WAIT))
                }
            }
            reader.releaseLock()
        }

        loop().then(() => {
            console.log("csmSerial infinite loop: end")
        })
        

        return () => {
            console.log("csmSerial infinite loop: releasing the reader")
            if (reader !== null) {
                try {
                    reader.releaseLock()
                } catch {}
            }   
        }
    }, [ csmSerialPort ])

    // detection loop
    useEffect(() => {
        if (!navigatorSupportsSerial()) {
            return
        }
        var mutex = true
        let i = setInterval(async () => {
            if (!mutex) {
                console.debug('csmSerial port detection: port detection mutex is false - skipping')
                return
            }
            if (
              csmSerialPort !== undefined &&
              csmSerialPort.readable !== null &&
              csmSerialPort.writable !== null
            ) {
                return
            }
            console.debug('setting csmSerial port detection mutex to false')
            mutex = false
            setCSMIsConnected(false)
            try {
                let port = await detectCsmSerialPort()
                if (port === undefined) {
                    console.debug("no csmSerial port found")
                    mutex = true
                    return
                }
                try {
                    // initialize csmSerial port
                    // Port must be open AND written/read before it can be used (port.writable !== null && port.readable !== null)
                    // TODO

                    // let cmdVersions = csmMosiGetVersions()
                    // await writeWithTimeout(port, cmdVersions, DEFAULT_WRITE_TIMEOUT)
                    // let t0 = Date.now()
                    // while (true) {
                    //     let respBytes = await readMsgWithTimeout(port, DEFAULT_READ_TIMEOUT)
                    //     let resp = decodeMisoFrame(respBytes)
                    //     if (resp.Type === CSM_PROTOCOL_COMMAND_TYPE.GetVersions) {
                    //         let payload = parseResponsePayload(resp.Payload)
                    //         let versions = parseVersionsResponse(payload.Data)
                    //         console.log("got versions", versions)
                    //         break
                    //     }
                    //     let t1 = Date.now()
                    //         if (t1 - t0 > 3*DEFAULT_READ_TIMEOUT) {
                    //         console.log("timeout waiting for response to GetVersions command")
                    //         break
                    //     }
                    // }
                } catch(e) {
                    console.log("error initializing csmSerial:", e)
                    void await port.close()
                    mutex = true
                    return
                }
                setCSMSerialPort(port)
                console.debug("csmSerial port set", port)
            } catch(e) {
                console.debug("no csmSerial port found")
            }
            console.debug('setting csmSerial port detection mutex to true')
            mutex = true
            return
        }, DEFAULT_DEVICE_DETECTION_INTERVAL)
        return () => {
            console.debug('clearing interval for csmSerial port detection')
            clearInterval(i)
            console.debug('setting csmSerial port detection mutex to true')
            mutex = true
            return
        }
    }, [ csmSerialPort ])

    // send commands
    useEffect(() => {
        if (csmCommands.length === 0) {
            // console.log('csmSerial dispatcher: no commands to send')
            return
        }
        if (csmSerialPort === undefined || csmSerialPort.writable === null) {
            console.log("csmSerial dispatcher: csmSerial port is undefined or not writable. will not send commands")
            return
        }
        let t = Date.now()
        // console.log('acquiring writeMutex..')
        writeMutexRef.current.acquire()
        .then(release => {
            // console.log('csmSerial: writeMutex acquired after ', Date.now() - t, 'ms')
            for (let cmd of csmCommands) {
                console.log("csmSerial dispatcher: sending command", cmd.message.CmdType)
                try {
                    let frame = encodeMosiCommand(cmd.message.CmdType, cmd.message.Payload)
                    // console.log('sleeping for 100ms')
                    setTimeout(() => {
                        writeWithTimeout(csmSerialPort, frame, DEFAULT_WRITE_TIMEOUT)
                        .catch((e: any) => {
                            console.log('csmSerial: error writing to csmSerial port', e.message)
                        
                        })
                        .finally(() => {
                            consumeCSMCommand(cmd.id)
                            // console.log('releasing mosi mutex')
                            release()
                        })
                    }, 100)
                } catch(e: any) {
                    console.log('error writing to mosi characteristic', e.message)
                }
                break
            }
        })
    }, [csmCommands, csmSerialPort])

    if (!navigatorSupportsSerial()) {
        return null
    }

    return <ConnectionIndicator
        name="CoreSensor Module (USB)"
        connectPath='/connect/csm/serial'
        isConnected={csmIsConnected}
        minimized={minimized}
        queueLength={csmMessages.length}
    />
}