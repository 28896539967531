import { DEFAULT_SERIAL_BUFFER_SIZE } from "../constants/commonConstants"
import { CSM_SERIAL_FILTER, CSM_SERIAL_DEFAULT_BAUD_RATE } from "../constants/csmSerialConstants"
import { CSM_PROTOCOL_START, CSM_PROTOCOL_STOP } from "../protocols/csmProtocol"
import { requestPort } from "../utils"

export const requestCsmSerialPort = async (): Promise<SerialPort | undefined> => {
    return await requestPort(
        CSM_SERIAL_DEFAULT_BAUD_RATE,
        [CSM_SERIAL_FILTER]
    )
}

export const detectCsmSerialPort = async (): Promise<SerialPort | undefined> => {
    if (! ("serial" in navigator)) {
        throw new Error("WebSerial not supported")
    }
    try {
        let ports = await navigator.serial.getPorts()
        ports = ports.filter(p => (
            p.getInfo().usbVendorId === CSM_SERIAL_FILTER.usbVendorId &&
            p.getInfo().usbProductId === CSM_SERIAL_FILTER.usbProductId
        ))
        if (ports.length == 0) {
            throw new Error("no ports available")
        }
        for (let port of ports) {
            let info = port.getInfo()
            if (info.usbVendorId === CSM_SERIAL_FILTER.usbVendorId &&
                info.usbProductId === CSM_SERIAL_FILTER.usbProductId
            ) {
                try {
                    await port.open({ baudRate: CSM_SERIAL_DEFAULT_BAUD_RATE, bufferSize: DEFAULT_SERIAL_BUFFER_SIZE })
                } catch (e: any) {
                    console.warn(`csmSerial port ${port.getInfo().usbProductId} failed to open: ${e.message}`)
                }
                return port
            }
        }
    } catch(e) {
        throw e
    }
}

export const csmSerialPortExists = async () => {
    let ports = await navigator.serial.getPorts()
    ports = ports.filter(p => (
        p.getInfo().usbVendorId === CSM_SERIAL_FILTER.usbVendorId &&
        p.getInfo().usbProductId === CSM_SERIAL_FILTER.usbProductId
    ))
    console.log('csmSerial ports', ports)
    if (ports.length > 0) {
        return true
    }
    return false
}

const CSM_SERIAL_BUFFER = new Uint8Array(2048)
var CSM_SERIAL_OFFSET = 0

// Takes a pre-borrowed and pre-blocked reader
// It is caller responsibility to release it after use
export const readMsgBlocking = async (
    reader: ReadableStreamDefaultReader<Uint8Array> | null,
): Promise<Uint8Array> => {
    if (reader === null) {
        throw new Error("port not readable")
    }
    while (true) {
        let startIdx = -1
        let endIdx = -1
        for (let i = 0; i < CSM_SERIAL_BUFFER.byteLength; i++) {
            if (CSM_SERIAL_BUFFER[i] === CSM_PROTOCOL_START) {
                startIdx = i
                endIdx = -1
                continue
            }
            if (startIdx >= 0 && CSM_SERIAL_BUFFER[i] === CSM_PROTOCOL_STOP) {
                endIdx = i
                break
            }
        }
        if (startIdx >= 0 && endIdx > 0) {
            // console.debug(`readMsgBlocking: payload found: startIdx: ${startIdx}, endIdx: ${endIdx}`, CSM_SERIAL_BUFFER)
            let payload = CSM_SERIAL_BUFFER.slice(startIdx, endIdx + 1)
            let remainder = CSM_SERIAL_BUFFER.slice(endIdx + 1, CSM_SERIAL_OFFSET)
            CSM_SERIAL_BUFFER.set(
                remainder,
                0
            )
            CSM_SERIAL_BUFFER.fill(0, remainder.byteLength)
            CSM_SERIAL_OFFSET = remainder.byteLength
            // console.debug(`readMsgBlocking: payload found, sending back: ${payload.byteLength}`, payload)
            return payload.slice(1, payload.byteLength - 1) // trim start and stop bytes
        }
        const { value } = await reader.read()
        if (value == undefined) {
            throw new Error('empty value')
        }
        // console.debug(`readMsgBlocking: value received: ${value.byteLength}`, value)
        if (CSM_SERIAL_OFFSET + value.byteLength >= CSM_SERIAL_BUFFER.byteLength) {
            console.warn('readMsgBlocking: CSM_SERIAL_BUFFER full, resetting..')
            CSM_SERIAL_BUFFER.fill(0)
            CSM_SERIAL_OFFSET = 0
        }
        CSM_SERIAL_BUFFER.set(value, CSM_SERIAL_OFFSET)
        CSM_SERIAL_OFFSET += value.byteLength
    }
}

// Will borrow port's reader, block it, and release after message is received or timeout
export const readMsgWithTimeout = async (
    port: SerialPort,
    timeout: number
): Promise<Uint8Array> => {
    if (port.readable === null) {
        throw new Error("port not readable")
    }
    let reader = port.readable.getReader()
    let t = setTimeout(() => {
        reader.releaseLock()
    }, timeout)
    try {
        let msg = await readMsgBlocking(reader)
        clearTimeout(t)
        reader.releaseLock()
        return msg
    }
    catch(e: any) {
        if (e.message && e.message.includes("Releasing")) {
            throw new Error('timeout')
        }
        throw e
    }
}

