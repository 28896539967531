import { FC, useEffect, useRef, useState } from "react"
import { useMessageContext } from "../reducers/messageContext"
import { FlexCol, FlexRow, Paper } from "../common"

import uPlot from "uplot"
import "uplot/dist/uPlot.min.css"
import { AutoComplete, Button, Col, Divider, Empty, Modal, Radio, Row, Spin, Tooltip, Typography, message } from "antd"
import { DeviceValue, RecordKey, newRecord, commitSensogramPartition, updateRecordDescription, updateRecordName, getAllRecords, getFullRecord, RecordValueWithSensograms, updateRecordBoundaries } from "../idb/idb"
import { DEFAULT_PLOT_DECIMATED_FPS, DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC, DEFAULT_RAW_FPS, IDB_PARTITION_WINDOW_SIZE, PLOT_WINDOW_SIZE, DEFAULT_STORAGE_DECIMATED_FPS } from "../constants"
import TextArea from "antd/es/input/TextArea"
import { ARYBALLE_COLOR_CYAN, ARYBALLE_COLOR_GRAY, ARYBALLE_COLOR_GRAY_DARK, DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, PEPTIDE_COLOR_MAP_VDW, colorHexToRGBA, rowIdxToLetter, spotsgrid1dIndexTo2dCoordinates } from "../utils"
import { BoxPlotOutlined, BoxPlotTwoTone, BugOutlined, BugTwoTone, ForwardOutlined, LinkOutlined, LoadingOutlined, PushpinOutlined, PushpinTwoTone, QuestionCircleOutlined, VerticalAlignBottomOutlined, VideoCameraOutlined } from "@ant-design/icons"
import { transpose, mean, standardDeviation } from "../analysis/utils"
import { aggregateSignature, sortSignature, normalizeL2 } from "../analysis/compute"
import { QuestonningResultWidget } from "../widgets/questionningResultWidget"
import { SingleSensogramFigure } from "../widgets/recordSensogram"
import { loadSpotsGrid1D } from "../localStorage"
import { Link } from "react-router-dom"
import { Mutex, MutexInterface, withTimeout } from "async-mutex"
import { DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE } from "../serial/constants/noaConstants"
import { refkitProtocolSetMMI } from "../serial/protocols/refkitProtocol"
import { useMetadataContext } from "../reducers/metadataContext"
import { navigatorSupportsSerial } from "../serial/utils"
import { WebserialNotSupportedWidget } from "../widgets/webserialNotSupportedWidget"
import { mmi2mzi } from "../analysis/mzi"
import { tryPostRecord, tryPostSensogramPartition } from "../api"
import { useOktaAuth } from "@okta/okta-react"

enum SenseMode {
    Recording,
    Questionning
}

const RecordNameModalDialog: FC<{
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    recordKey: RecordKey | null
}> = ({
    isOpen,
    setIsOpen,
    recordKey
}) => {

    const [ name, setName ] = useState<string>("")
    const [ description, setDescription ] = useState<string>("")

    const [ record, setRecord ] = useState<RecordValueWithSensograms | null>(null)

    const [ nameOptions, setNameOptions ] = useState<string[]>([])
    const [ currentOptions, setCurrentOptions ] = useState<string[]>([])

    const [ isLoading, setIsLoading ] = useState<boolean>(true)

    useEffect(() => {
        getAllRecords()
        .then((records) => {
            setNameOptions(
                Array.from(new Set(
                    records.filter((record) => record.name !== undefined).map((record) => record.name as string)
                ))
            )
        })
        setName("")
    }, [recordKey])

    useEffect(() => {
        if (recordKey === null) {
            return
        }
        if (!isOpen) {
            setRecord(null)
            return
        }
        setTimeout(() => {
            if (recordKey === null) {
                return
            }
            getFullRecord(recordKey)
            .then((record) => {
                console.log("record name modal dialog: record", record)
                setRecord(record)
                setIsLoading(false)
            })
        }, 100)
    }, [ recordKey, isOpen ])

    const onOk = async () => {
        if (recordKey === null) {
            console.log("record name modal dialog: record key is null")
            return
        }
        try {
            await updateRecordName(recordKey, name)
        } catch(e: any) {
            if (e && e.message) {
                message.error(`Could not update record name: ${e.message}`)
            }
            return
        }
        try {
            await updateRecordDescription(recordKey, description)
        } catch(e: any) {
            if (e && e.message) {
                message.error(`Could not update record description: ${e.message}`)
            }
            return
        }
        setIsOpen(false)
        message.success(<>Saved the record under name: <b>{name}</b>!</>)
    }

    return <Modal
        title="New Record"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={onOk}
    >
        <FlexCol>
            Name:
            <AutoComplete
                // ref={(input) => input && input.focus()}
                backfill
                tabIndex={0}
                autoFocus
                placeholder="Name"
                options={currentOptions.map((name) => {
                    return {
                        value: name
                    }
                })}
                onSearch={(value) => {
                    let _currentOptions = nameOptions.filter((name) => name.toLowerCase().includes(value.toLowerCase()))
                    setCurrentOptions(
                        _currentOptions
                    )
                }}
                value={name}
                onChange={(value) => setName(value)}
            />
            Description:
            <TextArea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
        </FlexCol>
        { record ?
            <Row gutter={[5, 5]}>
                <Col xs={24} >
                    <div style={{
                        width: '100%',
                        height: 300,
                    }}>
                        <SingleSensogramFigure
                            record={record}
                            shouldAggregate={true}
                            setBoundaries={async (startIdx, endIdx) => {
                                record.analyteStart = startIdx
                                record.analyteEnd = endIdx
                                void await updateRecordBoundaries(
                                    record.key,
                                    undefined,
                                    undefined,
                                    startIdx,
                                    endIdx
                                )
                                // TODO: rerender by setting the existing record, not a newly-fetched one
                                setRecord(record)
                            }}
                        />
                    </div>
                </Col> 
                {/* <Col xs={24} lg={8}>
                    <div style={{
                        width: '100%',
                        height: 300,
                    }}>
                        <SingleSignatureFigure
                            record={record}
                            shouldAggregate={true}
                        />
                    </div>
                </Col> */}
            </Row>
        : <FlexRow style={{
            width: '100%',
            height: 300,
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Spin/>
        </FlexRow>
        }
    </Modal>
}

export const NoaSensePage: FC = () => {
    const {
        refkitMessages,
        refkitPort,
        refkitIsConnected,
        consumeRefkitMessage,
        clearRefkitMessages,
    } = useMessageContext()

    const {
        thimphuFspMetadata,
        refkitVersion,
        refkitConfig,
    } = useMetadataContext()

    const {authState, oktaAuth } = useOktaAuth()
    const [ userID, setUserID ] = useState<string | null>(null)

    const [mziUplotOptions, setMziUplotOptions] = useState<uPlot.Options | null>(null)

    const [mziUplotData, setMziUplotData] = useState<uPlot.AlignedData>([])
    const [fpsUplotData, setFpsUplotData] = useState<uPlot.AlignedData>([])

    const mziTargetRef = useRef<HTMLDivElement>(null)
    const mziUplotRef = useRef<uPlot | null>(null);
    const mziTooltipRef = useRef<HTMLDivElement>(null)

    const fpsTargetRef = useRef<HTMLDivElement>(null)
    const fpsUplotRef = useRef<uPlot | null>(null);

    const firstMZIsRef = useRef<number[] | null>(null)
    const previousMZIsRef = useRef<number[] | null>(null)
    const KsRef = useRef<number[] | null>(null)

    const noizeLevelRef = useRef<number>(0)
    const isOdorPresentRef = useRef<boolean>(false)
    const odorPresenceThresholdLevelRef = useRef<number>(0)
    const maxOdorPresentValue = useRef<number>(0)
    const odorPresentStartTimestampRef = useRef<number>(0)
    const odorPresentStopTimestampRef = useRef<number>(0)
    const odorPresentLastRecognitionTimestampRef = useRef<number>(0)
    const signalEnvelopeMinRef = useRef<number>(0)
    const signalEnvelopeMaxRef = useRef<number>(0)
    const signalEnvelopeAvgRef = useRef<number>(0)

    const decimatedMZISeriesRef = useRef<number[][]>([])
    const rawMZISeriesRef = useRef<number[][]>([])

    const decimatedTimestampSeriesRef = useRef<number[]>([])
    const rawTimestampSeriesRef = useRef<number[]>([])

    const decimatedMZIPartitionSeriesRef = useRef<number[][]>([])
    const decimatedTimestampPartitionSeriesRef = useRef<number[]>([])

    const decimatedFpsTimeseriesRef = useRef<number[]>([])
    const rawFpsTimeseriesRef = useRef<number[]>([])

    const [ rawFps, setRawFps ] = useState<number>(0)
    const [ decimatedFps, setDecimatedFps ] = useState<number>(0)

    const lastDecimationTickRef = useRef<number>(0)

    const [ currentSpotsgrid1d, setCurrentSpotsgrid1d ] = useState<number[] | null>(null)
    const [ aggregatedIndicesMap, setAggregatedIndicesMap ] = useState<Record<number, number[]>>({})

    const [ isLoading, setIsLoading ] = useState<boolean>(true)

    const recordKeyRef = useRef<RecordKey | null>(null)

    const [ isSensing, setIsSensing ] = useState<boolean>(true)
    const [ isRecording, setIsRecording ] = useState<boolean>(false)
    const [ recordStartTimestamp, setRecordStartTimestamp ] = useState<number>(0)

    const [ deviceValue, setDeviceValue ] = useState<DeviceValue | null>(null)
    const [ isRecordModalOpen, setIsRecordModalOpen ] = useState<boolean>(false)

    const [ shouldAggregate, setShouldAggregate ] = useState<boolean>(true)
    const [ showDebugInfo, setShowDebugInfo ] = useState<boolean>(false)
    const [ shouldRedraw, setShouldRedraw ] = useState<boolean>(false)
    const [ pinLastQuestionningResult, setPinLastQuestionningResult ] = useState<boolean>(true)

    const [ senseMode, setSenseMode ] = useState<SenseMode>(SenseMode.Recording)

    const [ questionningSignature, setQuestionningSignature ] = useState<number[] | null>(null)
    const [ questionningSpotsgrid1d, setQuestionningSpotsgrid1d ] = useState<number[] | null>(null)

    const messageQueueMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 300))


    useEffect(() => {
        if (authState && authState.isAuthenticated && authState.idToken && authState.idToken.claims) {
            setUserID(authState.idToken.claims.sub)
        }
    }, [authState, oktaAuth])

    useEffect(() => {
        let t = setTimeout(() => {
            if (!refkitPort || !refkitIsConnected) {
                return
            }
            clearRefkitMessages()
            refkitProtocolSetMMI(refkitPort, true).then(() => {
                console.log("sense page: set refkit mmi to true on mount")
                setIsLoading(false)
            })
        }, 500)
        return () => {
            clearTimeout(t)
            if (!refkitPort || !refkitIsConnected) {
                return
            }
            clearRefkitMessages()
            refkitProtocolSetMMI(refkitPort, false).then(() => {
                setIsLoading(true)
                console.log("sense page: set refkit mmi to false on unmount")
            })
        }
    }, [ refkitPort, refkitIsConnected, currentSpotsgrid1d ])

    useEffect(() => {
        let _spotsgrid1d = loadSpotsGrid1D()
        if (!_spotsgrid1d) {
            console.log("sense page: spotsgrid1d is empty")
            return
        }
        setCurrentSpotsgrid1d(_spotsgrid1d)
        // Aggregate MZIs by peptide
        let _aggregationIndicesMap: Record<number, number[]> = {}
        for (let i = 0; i < _spotsgrid1d.length; i++) {
            let aggKey = _spotsgrid1d[i]
            if (aggKey < 0) {
                continue
            }
            if (_aggregationIndicesMap[aggKey] === undefined) {
                _aggregationIndicesMap[aggKey] = []
            }
            _aggregationIndicesMap[aggKey].push(i)
        }
        setAggregatedIndicesMap(_aggregationIndicesMap)
        // console.log("sense page: _aggregationIndicesMap", _aggregationIndicesMap)
    }, [ thimphuFspMetadata ])

    useEffect(() => {
        if (refkitMessages.length === 0) {
            return
        }
        if (messageQueueMutexRef.current.isLocked()) {
            return
        }
        let t = Date.now()
        // console.log("sense page: acquiring mutex..")
        messageQueueMutexRef.current.acquire().then((release) => {
        // console.log("sense page: acquired mutex in ", Date.now() - t, "ms")
        let nFramesOnOneMutexLock = 0
        for (let message of refkitMessages) {
            if (
                message.message.result &&
                message.message.result.header &&
                !message.message.result.header.status
            ) {
                console.log("sense page: refkit message error", message.message.result.header.reason)
                consumeRefkitMessage(message.id)
                continue
            }
            // console.log("sense page: refkit message", message)
            if (
                !message.message.event ||
                !message.message.event.algo
            ) {
                console.log("sense page: refkit message is not an event", message.message)
                consumeRefkitMessage(message.id)
            } else {
                nFramesOnOneMutexLock++
                consumeRefkitMessage(message.id)

                // load the spotsgrid
                if (!currentSpotsgrid1d) {
                    console.log("sense page: spotsgrid is empty")
                    return
                }

                let tick = message.ts
                // console.log("sense page: ts", ts)
                let mmis = message.message.event.algo.peaks
                if (!mmis || mmis.length === 0) {
                    console.log("sense page: peaks are empty")
                    continue
                }
                
                let mzis = mmi2mzi(mmis)

                if (KsRef.current === null) {
                    KsRef.current = new Array(mzis.length).fill(0)
                }

                for (let i = 0; i < mzis.length; i++) {
                    let k = KsRef.current[i]
                    mzis[i] += k*2*Math.PI
                }

                if (previousMZIsRef.current !== null) {
                    for (let i = 0; i < mzis.length; i++) {
                        let mzi = mzis[i];
                        const previousMzi = previousMZIsRef.current[i];
                        let k = KsRef.current[i];
                        let diff = mzi - previousMzi
                        if (diff > Math.PI) {
                            k -= 1
                            mzi -= 2*Math.PI
                        } else if (diff < -Math.PI) {
                            k += 1
                            mzi += 2*Math.PI
                        }
                        mzis[i] = mzi
                        KsRef.current[i] = k
                    }
                }
                previousMZIsRef.current = mzis

                rawMZISeriesRef.current.push(mzis)
                rawTimestampSeriesRef.current.push(tick)

                if (tick - lastDecimationTickRef.current < 1000 / DEFAULT_PLOT_DECIMATED_FPS) {
                    continue;
                }
                lastDecimationTickRef.current = tick

                decimatedTimestampSeriesRef.current.push(tick)
                // console.log("sense page: decimated timestamp timeseries", decimatedTimestampTimeseriesRef.current)

                if (decimatedTimestampSeriesRef.current.length > PLOT_WINDOW_SIZE) {
                    decimatedTimestampSeriesRef.current.shift()
                }

                // calculate raw timestamp intervals
                let rawTimestampIntervals: number[] = []
                for (let i = 0; i < rawTimestampSeriesRef.current.length - 1; i++) {
                    rawTimestampIntervals[i] = rawTimestampSeriesRef.current[i+1] - rawTimestampSeriesRef.current[i]
                }
                rawTimestampSeriesRef.current = []
                let rawTimestampInterval = rawTimestampIntervals.reduce((a, b) => a + b, 0) / rawTimestampIntervals.length
                let rawFps = 1000 / rawTimestampInterval
                setRawFps(rawFps)

                if (!isNaN(rawFps)) {
                    rawFpsTimeseriesRef.current.push(rawFps)
                }
                if (rawFpsTimeseriesRef.current.length > PLOT_WINDOW_SIZE) {
                    rawFpsTimeseriesRef.current.shift()
                }

                // calculate decimated timestamp intervals 
                let decimatedTimestampIntervals: number[] = []
                for (let i = 0; i < decimatedTimestampSeriesRef.current.length - 1; i++) {
                    decimatedTimestampIntervals[i] = decimatedTimestampSeriesRef.current[i+1] - decimatedTimestampSeriesRef.current[i]
                }
                let decimatedTimestampInterval = decimatedTimestampIntervals.reduce((a, b) => a + b, 0) / decimatedTimestampIntervals.length
                // console.log("sense page: decimated timestamp interval", decimatedTimestampInterval, decimatedTimestampIntervals)
                let decimatedFps = 1000 / decimatedTimestampInterval
                setDecimatedFps(decimatedFps)

                if (!isNaN(decimatedFps)) {
                    decimatedFpsTimeseriesRef.current.push(decimatedFps)
                }
                if (decimatedFpsTimeseriesRef.current.length > PLOT_WINDOW_SIZE) {
                    decimatedFpsTimeseriesRef.current.shift()
                }

                // decimate MZIs by averaging over DECIMATION_WINDOW_SIZE
                let decimatedMzis: number[] = []
                for (let i = 0; i < currentSpotsgrid1d.length; i++) {
                    let sum = 0
                    for (let j = 0; j < rawMZISeriesRef.current.length; j++) {
                        sum += rawMZISeriesRef.current[j][i]
                    }
                    decimatedMzis[i] = sum / rawMZISeriesRef.current.length
                }
                rawMZISeriesRef.current = []

                // subtract first mzis and save them
                if (firstMZIsRef.current === null) {
                    firstMZIsRef.current = [...decimatedMzis]
                    // console.log("sense page: first mzis is null. setting to", firstMZIsRef.current)
                }
                for (let i = 0; i < currentSpotsgrid1d.length; i++) {
                    decimatedMzis[i] -= firstMZIsRef.current[i]
                }

                decimatedMZISeriesRef.current.push(decimatedMzis)
                if (decimatedMZISeriesRef.current.length > PLOT_WINDOW_SIZE) {
                    decimatedMZISeriesRef.current.shift()
                }

                decimatedTimestampPartitionSeriesRef.current.push(tick)
                decimatedMZIPartitionSeriesRef.current.push(decimatedMzis) // save non-aggreated mzis regardless of shouldAggregate

                if (decimatedMZIPartitionSeriesRef.current.length >= IDB_PARTITION_WINDOW_SIZE) {
                    if (isRecording && recordKeyRef.current !== null ) {
                        // average decimate decimatedMZIPartitionSeriesRef by 2 for storage
                        // (storage decimation is half the plotting one)
                        let storageDecimatedMZIPartitionSeries: number[][] = []
                        let storageDecimatedTimestampPartitionSeries: number[] = []
                        let storageDecimationFactor = Math.floor(DEFAULT_PLOT_DECIMATED_FPS / DEFAULT_STORAGE_DECIMATED_FPS)
                        for (let i = 0; i < decimatedMZIPartitionSeriesRef.current.length; i += storageDecimationFactor) {
                            let storageDecimatedMzis: number[] = []
                            for (let j = 0; j < currentSpotsgrid1d.length; j++) {
                                let sum = 0
                                for (let ii = 0; ii < storageDecimationFactor; ii++) {
                                    sum += decimatedMZIPartitionSeriesRef.current[i+ii][j]
                                }
                                storageDecimatedMzis[j] = sum / storageDecimationFactor
                            }
                            storageDecimatedMZIPartitionSeries.push(storageDecimatedMzis)
                            // timestamps are just subsampled (not averaged)
                            storageDecimatedTimestampPartitionSeries.push(
                                decimatedTimestampPartitionSeriesRef.current[i]
                            )
                        }
                        if (recordKeyRef.current !== null) {
                            let _recordKey = '' + recordKeyRef.current
                            commitSensogramPartition(
                                _recordKey,
                                storageDecimatedMZIPartitionSeries,
                                storageDecimatedTimestampPartitionSeries
                            ).then((partitionKey) => {
                                console.log("sense page: saved partition")
                                tryPostSensogramPartition(
                                    _recordKey,
                                    partitionKey
                                )
                            })
                            .catch((e: any) => {
                                console.log("sense page: could not save partition", e)
                            })
                        }
                    }
                    decimatedMZIPartitionSeriesRef.current = []
                    decimatedTimestampPartitionSeriesRef.current = []
                }

                let seriesLabels: number[] = []
                if (shouldAggregate) {
                    seriesLabels = Object.keys(aggregatedIndicesMap).map((aggKey) => parseInt(aggKey))
                } else {
                    seriesLabels = [...currentSpotsgrid1d]
                }
                let nDims = seriesLabels.length

                // build mzi uplot data
                let X = decimatedTimestampSeriesRef.current.map((ts) => ts / 1000)

                // aggregate mzis timeseries (frame by frame) if needed
                let finalMZIsSeries: number[][] = []
                if (shouldAggregate) {
                    for (let j = 0; j < decimatedMZISeriesRef.current.length; j++) {
                        let finalMZIs: number[] = []    
                        for (let aggKey in aggregatedIndicesMap) {
                            let aggIndices = aggregatedIndicesMap[aggKey]
                            let sum = 0
                            for (let i = 0; i < aggIndices.length; i++) {
                                sum += decimatedMZISeriesRef.current[j][aggIndices[i]]
                            }
                            finalMZIs.push(sum / aggIndices.length)
                        }
                        finalMZIsSeries.push(finalMZIs)
                    }
                } else {
                    finalMZIsSeries = decimatedMZISeriesRef.current
                }

                if (finalMZIsSeries.length < 2) {
                    continue;
                }

                //
                let lastFrame: number[] = finalMZIsSeries[finalMZIsSeries.length-1]
                let lastFrameSum: number = 0
                signalEnvelopeMinRef.current = 1e6
                signalEnvelopeMaxRef.current = -1e6
                for (let i = 0; i < lastFrame.length; i++) {
                    if (lastFrame[i] < signalEnvelopeMinRef.current) {
                        signalEnvelopeMinRef.current = lastFrame[i]
                    }
                    if (lastFrame[i] > signalEnvelopeMaxRef.current) {
                        signalEnvelopeMaxRef.current = lastFrame[i]
                    }
                    lastFrameSum += lastFrame[i]
                }
                signalEnvelopeAvgRef.current = lastFrameSum / lastFrame.length
                let previousFrameMean: number = mean(finalMZIsSeries[finalMZIsSeries.length-2])

                let avgSeries: number[] = []
                for (let i = 0; i < finalMZIsSeries.length; i++) {
                    avgSeries.push(mean(finalMZIsSeries[i]))
                }

                if (!isOdorPresentRef.current && noizeLevelRef.current > 0 && signalEnvelopeAvgRef.current > noizeLevelRef.current) {
                    odorPresenceThresholdLevelRef.current = mean([
                        previousFrameMean,
                        signalEnvelopeAvgRef.current
                    ])
                    odorPresentStartTimestampRef.current = decimatedTimestampSeriesRef.current[decimatedTimestampSeriesRef.current.length-1]
                    odorPresentStopTimestampRef.current = 0
                    odorPresentLastRecognitionTimestampRef.current = Date.now()
                    isOdorPresentRef.current = true
                }

                if (
                    isOdorPresentRef.current &&
                    (
                        signalEnvelopeAvgRef.current < Math.max(
                            maxOdorPresentValue.current*DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE,
                            odorPresenceThresholdLevelRef.current
                        )
                    )
                ) {
                    isOdorPresentRef.current = false
                    maxOdorPresentValue.current = 0
                    odorPresenceThresholdLevelRef.current = 0
                    odorPresentStopTimestampRef.current = decimatedTimestampSeriesRef.current[decimatedTimestampSeriesRef.current.length-1]
                    if (!pinLastQuestionningResult) {
                        setQuestionningSignature(null)
                    } else {
                        constructSignatureAndRecognize()
                    }
                }

                if (!isOdorPresentRef.current) {
                    let noizeSeries = avgSeries.slice(-4*DEFAULT_PLOT_DECIMATED_FPS, -1*DEFAULT_PLOT_DECIMATED_FPS)
                    noizeLevelRef.current = mean(noizeSeries) + standardDeviation(noizeSeries) * 12
                }

                if (isOdorPresentRef.current) {
                    maxOdorPresentValue.current = Math.max(
                        maxOdorPresentValue.current,
                        signalEnvelopeAvgRef.current
                    )
                }

                if (isOdorPresentRef.current && (Date.now() - odorPresentLastRecognitionTimestampRef.current) > 250) {
                    constructSignatureAndRecognize()
                    odorPresentLastRecognitionTimestampRef.current = Date.now()
                }

                // build uplot options
                if (mziUplotOptions === null) {
                    const opts: uPlot.Options = {
                        id: `uplot-chart-mmi`,
                        width: 0,
                        height: 0,
                        padding: [0, 50, 0, 0],
                        legend: {
                            show: false,
                        },
                        scales: {
                            y: {
                                range: (u, min, max) => min < 0 ? [min, max] : [0, max]
                            }
                        },
                        pxAlign: 0,
                        series: [
                            {},
                            // sensogram series
                            ...seriesLabels.map((spotInt) => {
                                let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE
                                let peptideInt = spotInt
                                if (peptideInt < 0) {
                                    peptideInt *= -1
                                }
                                let peptideStr = spotInt.toString()
                                if (peptideStr.length === 3 && peptideStr[2] === '4') {
                                    peptideInt = parseInt(peptideStr.slice(0, 2))
                                }
                                if (PEPTIDE_COLOR_MAP_VDW[peptideInt]) {
                                    color = PEPTIDE_COLOR_MAP_VDW[peptideInt]
                                }
                                let label = peptideInt.toString()
                                return {
                                    show: spotInt > 1,
                                    spanGaps: false,
                                    label: label,
                                    stroke: color,
                                    width: 2,
                                } as uPlot.Series
                            }),
                            // Avg series
                            {
                                show: true,
                                spanGaps: false,
                                label: "Avg",
                                stroke: "rgba(0,0,0,0.7)",
                                width: 2,
                            } as uPlot.Series
                        ],
                        hooks: {
                            setSeries: [
                                (u, seriesIdx) => {
                                    try {
                                        // console.log("sense page: set series", seriesIdx)
                                        if (mziTooltipRef.current === null) {
                                            return
                                        }
                                        if (seriesIdx === null) {
                                            return
                                        }
                                        let seriesLabel = u.series[seriesIdx].label
                                        if (seriesLabel === undefined) {
                                            return
                                        }
                                        let peptideInt = parseInt(seriesLabel)
                                        let color = DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE
                                        if (seriesLabel === "Avg") {
                                            color = "rgba(0,0,0,0.7)"
                                        }
                                        if (PEPTIDE_COLOR_MAP_VDW[peptideInt]) {
                                            color = PEPTIDE_COLOR_MAP_VDW[peptideInt]
                                        }
                                        let tooltip = seriesLabel
                                        if (!shouldAggregate) {
                                            let [ row, col ] = spotsgrid1dIndexTo2dCoordinates(seriesIdx-1)
                                            let rowStr = rowIdxToLetter(row)
                                            tooltip += ` [${rowStr}${col}]`
                                        }
                                        mziTooltipRef.current.innerHTML = `<b>${tooltip}</b>`
                                        mziTooltipRef.current.style.backgroundColor = color
                                        mziTooltipRef.current.style.color = "white"
                                        mziTooltipRef.current.style.border = "1px solid white"
                                        mziTooltipRef.current.style.borderRadius = "5px"
                                        mziTooltipRef.current.style.padding = "5px"
                                    } catch(e) {
                                        console.log("sense page: set series error", e)
                                    }
                                }
                            ],
                            draw: [
                                // draw horizontal line at noize level
                                (u) => {
                                    const ctx = u.ctx
                                    const x0 = u.bbox.left
                                    const x1 = u.bbox.left + u.bbox.width
                                    const y = u.valToPos(noizeLevelRef.current, "y", true)
                                    ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_GRAY, 1)
                                    ctx.lineWidth = 2
                                    ctx.setLineDash([20, 5, 5, 5])
                                    ctx.beginPath()
                                    ctx.moveTo(x0, y)
                                    ctx.lineTo(x1, y)
                                    ctx.stroke()
                                },
                                // draw signal envelope and indicate average
                                (u) => {
                                    const ctx = u.ctx
                                    const yMax = u.valToPos(signalEnvelopeMaxRef.current, "y", true)
                                    const yAvg = u.valToPos(signalEnvelopeAvgRef.current, "y", true)
                                    const yMin = u.valToPos(signalEnvelopeMinRef.current, "y", true)
                                    let txt = signalEnvelopeAvgRef.current.toFixed(2)
                                    let txtWidth = ctx.measureText(txt).width
                                    const x = u.bbox.left + u.bbox.width + 20

                                    ctx.font = "16px sans-serif";
                                    ctx.fillStyle =  colorHexToRGBA(ARYBALLE_COLOR_GRAY_DARK, 1)
                                    ctx.fillText(signalEnvelopeAvgRef.current.toFixed(2), x+txtWidth+20, yAvg)

                                    // ctx.canvas.width = ctx.canvas.width + txtWidth + 20

                                    let bracketWidth = 5
                                    let pointerWidth = 10

                                    ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_GRAY_DARK, 1)
                                    ctx.lineWidth = 2
                                    ctx.setLineDash([1,0])
                                    ctx.beginPath()
                                    ctx.moveTo(x, yMax)
                                    ctx.lineTo(x+bracketWidth, yMax)
                                    ctx.lineTo(x+bracketWidth, yAvg)
                                    ctx.lineTo(x+bracketWidth+pointerWidth, yAvg)
                                    ctx.moveTo(x+bracketWidth, yAvg)
                                    ctx.lineTo(x+bracketWidth, yMin)
                                    ctx.lineTo(x, yMin)
                                    ctx.stroke()
                                },
                                // draw odor presence threshold
                                // (u) => {
                                //     if (!isOdorPresentRef.current) {
                                //         return
                                //     }
                                //     const ctx = u.ctx
                                //     const x0 = u.bbox.left
                                //     const x1 = u.bbox.left + u.bbox.width
                                //     const y = u.valToPos(odorPresenceThresholdLevelRef.current, "y", true)
                                //     ctx.strokeStyle = "rgba(255, 0, 0, 1)"
                                //     ctx.lineWidth = 2
                                //     ctx.setLineDash([10, 5]);
                                //     ctx.beginPath()
                                //     ctx.moveTo(x0, y)
                                //     ctx.lineTo(x1, y)                
                                //     ctx.stroke()
                                // },
                                // draw odor presence "corridor"
                                (u) => {
                                    if (!isOdorPresentRef.current) {
                                        return
                                    }
                                    const ctx = u.ctx
                                    const x0 = u.bbox.left
                                    const x1 = u.bbox.left + u.bbox.width
                                    const y0 = u.valToPos(
                                        Math.max(
                                            maxOdorPresentValue.current,
                                            signalEnvelopeMaxRef.current
                                        ),
                                        "y", true)
                                    const y1 = u.valToPos(
                                        Math.max(
                                            maxOdorPresentValue.current*DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE,
                                            odorPresenceThresholdLevelRef.current
                                        ),
                                        "y", true
                                    )
                                    ctx.fillStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 0.2)
                                    ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 1)
                                    ctx.lineWidth = 3
                                    ctx.setLineDash([10, 5]);
                                    ctx.beginPath()
                                    ctx.moveTo(x0, y0)
                                    ctx.lineTo(x1, y0)
                                    ctx.moveTo(x1, y1)
                                    ctx.lineTo(x0, y1)
                                    ctx.fillRect(x0, y1, x1-x0, y0-y1)
                                    ctx.stroke()
                                },
                                // draw odor presence start and stop timestamps
                                (u) => {
                                    const ctx = u.ctx
                                    ctx.strokeStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 1)
                                    ctx.lineWidth = 3
                                    ctx.setLineDash([10, 5])
                                    const yBot = u.bbox.top + u.bbox.height
                                    const yTop = u.bbox.top
                                    if (odorPresentStartTimestampRef.current > 0) {
                                        const startX = u.valToPos(odorPresentStartTimestampRef.current/1000, "x", true)
                                        ctx.beginPath()
                                        ctx.moveTo(startX, yBot)
                                        ctx.lineTo(startX, yTop)
                                        ctx.stroke()
                                    }
                                    if (odorPresentStopTimestampRef.current > 0) {
                                        const stopX = u.valToPos(odorPresentStopTimestampRef.current/1000, "x", true)
                                        ctx.beginPath()
                                        ctx.moveTo(stopX, yBot)
                                        ctx.lineTo(stopX, yTop)
                                        ctx.stroke()
                                    }
                                    if (odorPresentStartTimestampRef.current > 0 && odorPresentStopTimestampRef.current > 0) {
                                        const startX = u.valToPos(odorPresentStartTimestampRef.current/1000, "x", true)
                                        const stopX = u.valToPos(odorPresentStopTimestampRef.current/1000, "x", true)
                                        ctx.fillStyle = colorHexToRGBA(ARYBALLE_COLOR_CYAN, 0.2)
                                        ctx.fillRect(startX, yBot, stopX-startX, yTop-yBot)
                                    }

                                }
                            ]
                        },
                        focus: {
                            alpha: 0.3,
                        },
                        cursor: {
                            focus: {
                                prox: 10,
                            },
                        },
                    };
                    // console.log("sense page: uplot options", opts)
                    setMziUplotOptions(opts)
                }

                // transpose mzisTimeseriesRef.current into Ys
                let Ys = []
                for (let i = 0; i < nDims; i++) {
                    let Y = []
                    for (let j = 0; j < finalMZIsSeries.length; j++) {
                        Y[j] = finalMZIsSeries[j][i]
                    }
                    Ys.push(Y)
                }
                Ys.push(avgSeries)
                let data: uPlot.AlignedData = [X, ...Ys]
                setMziUplotData(data)
                setFpsUplotData([
                    X,
                    rawFpsTimeseriesRef.current,
                    decimatedFpsTimeseriesRef.current,
                    X.map(() => DEFAULT_RAW_FPS),
                    X.map(() => DEFAULT_PLOT_DECIMATED_FPS)
                ])
                continue;
            }
        }
        // console.log('processed nFramesOnOneMutexLock', nFramesOnOneMutexLock)
        release()
    })
    .catch((e: any) => {
        console.log("sense page: could not acquire mutex", e)
        messageQueueMutexRef.current.cancel()
        messageQueueMutexRef.current.release()
    })
    return () => {
        messageQueueMutexRef.current.cancel()
        messageQueueMutexRef.current.release()
    }
}, [refkitMessages])

    useEffect(() => {
        if (mziUplotOptions !== null && mziUplotData !== null && mziTargetRef.current !== null) {
            if (mziUplotRef.current === null || shouldRedraw) {
                if (mziUplotRef.current !== null) {
                    mziUplotRef.current.destroy()
                    mziUplotRef.current = null
                }
                let plot = new uPlot(mziUplotOptions, mziUplotData, mziTargetRef.current)
                plot.setSize({
                    width: mziTargetRef.current.clientWidth,
                    height: mziTargetRef.current.clientHeight
                })
                mziUplotRef.current = plot
                if (shouldRedraw) {
                    setShouldRedraw(false)
                }
            } else {
                mziUplotRef.current.setData(mziUplotData)
                mziUplotRef.current.setSize({
                    width: mziTargetRef.current.clientWidth,
                    height: mziTargetRef.current.clientHeight
                })
            }
        } else {
            console.log("sense page: could not create mzi uplot", mziUplotOptions, mziUplotData, mziTargetRef.current)
        }
    }, [mziUplotOptions, mziUplotData, mziTargetRef.current])

    useEffect(() => {
        if (fpsTargetRef.current !== null && fpsUplotData !== null) {
            if (fpsUplotRef.current === null || shouldRedraw) {
                if (fpsUplotRef.current !== null) {
                    fpsUplotRef.current.destroy()
                    fpsUplotRef.current = null
                }
                let opts: uPlot.Options = {
                    id: `uplot-chart-fps`,
                    width: 0,
                    height: 0,
                    legend: {
                        show: false,
                    },
                    series: [
                        {},
                        {
                            show: true,
                            spanGaps: false,
                            label: 'Raw FPS',
                            stroke: 'red',
                            value: (_, rawValue: number) => rawValue !== null ? rawValue.toFixed(2) : "N/A",
                        },
                        {
                            show: true,
                            spanGaps: false,
                            label: 'Decimated FPS',
                            stroke: 'blue',
                            value: (_, rawValue: number) => rawValue !== null ? rawValue.toFixed(2) : "N/A",
                        },
                        {
                            show: true,
                            stroke: "red",
                            dash: [10, 5],
                        },
                        {
                            show: true,
                            stroke: "blue",
                            dash: [10, 5],
                        }
                    ]
                }
                let plot = new uPlot(opts, fpsUplotData, fpsTargetRef.current)
                plot.setSize({
                    width: fpsTargetRef.current.clientWidth,
                    height: fpsTargetRef.current.clientHeight
                })
                fpsUplotRef.current = plot
            } else {
                fpsUplotRef.current.setData(fpsUplotData)
                fpsUplotRef.current.setSize({
                    width: fpsTargetRef.current.clientWidth,
                    height: fpsTargetRef.current.clientHeight
                })
            }
        } else {
            // console.log("sense page: could not create fps uplot", fpsUplotData, fpsTargetRef.current)
        }
    }, [fpsUplotData, fpsTargetRef.current])

    useEffect(() => {
        const cleanup = () => {
            console.info("sense page: destroying uplot refs")
            if (mziUplotRef.current !== null) {
                mziUplotRef.current.destroy()
                mziUplotRef.current = null
            }
            setMziUplotOptions(null)
            setMziUplotData([])

            if (fpsUplotRef.current !== null) {
                fpsUplotRef.current.destroy()
                fpsUplotRef.current = null
            }
            setFpsUplotData([])
        }
        cleanup()
        return cleanup
    }, [])

    useEffect(() => {
        const constructDeviceValue = async () => {
            let commonName = thimphuFspMetadata?.marketingName
            if (commonName === undefined || commonName === null) {
                commonName = ""
            }
            let shellSerial = thimphuFspMetadata?.shellSerial
            if (shellSerial === undefined || shellSerial === null) {
                shellSerial = ""
            }
            let coreSensorSerial = thimphuFspMetadata?.coreSensorSerial
            if (coreSensorSerial === undefined || coreSensorSerial === null) {
                coreSensorSerial = ""                
            }
            let fwVersion = refkitVersion?.fwVersion
            if (fwVersion === undefined || fwVersion === null) {
                throw new Error("fw version is undefined")
            }
            let hwVersion = refkitVersion?.hwVersion
            if (hwVersion === undefined || hwVersion === null) {
                throw new Error("hw version is undefined")
            }
            let cameraExposure = refkitConfig?.cameraExposure
            if (cameraExposure === undefined || cameraExposure === null) {
                throw new Error("camera exposure is undefined")
            }
            let spotsgrid = currentSpotsgrid1d
            if (spotsgrid === undefined || spotsgrid === null) {
                throw new Error("spotsgrid is undefined")
            }
            let _deviceValue = {
                commonName,
                shellSerial,
                coreSensorSerial,
                fwVersion,
                hwVersion,
                cameraExposure,
                spotsgrid
            }
            console.log("sense page: constructed device value", _deviceValue)
            return _deviceValue
        }
        constructDeviceValue()
        .then((_deviceValue) => {
            setDeviceValue(_deviceValue)
        })
        .catch((e: any) => {
            console.log("sense page: could not construct device", e)
        })
            
    }, [refkitVersion, refkitConfig, thimphuFspMetadata, currentSpotsgrid1d])

    const startRecording = async () => {
        try {
            if (deviceValue === null) {
                throw new Error("device value is null")
            }
            let recordKey = await newRecord(
                deviceValue,
            )
            // async try to post record to API
            if (userID !== null) {
                tryPostRecord(userID, recordKey)
            }
            console.info('sense page: start recording: record key', recordKey)
            recordKeyRef.current = recordKey
            setIsRecording(true)
            setRecordStartTimestamp(Date.now())
        } catch(e: any) {
            console.log("sense page: could not start recording", e)
            if (e && e.message) {
                message.error("Could not start recording: " + e)
            }
        }
    }

    const stopRecording = async () => {
        if (recordKeyRef.current === null) {
            console.log("sense page: record key is null")
            return
        }
        console.info('sense page: stop recording: record key', recordKeyRef.current)
        console.log('sense page: stop recording: about to commit the last partition', decimatedMZIPartitionSeriesRef.current)
        try {
            let partitionKey = await commitSensogramPartition(
                recordKeyRef.current,
                decimatedMZIPartitionSeriesRef.current,
                decimatedTimestampPartitionSeriesRef.current
            )
            console.log("sense page: saved partition")
            tryPostSensogramPartition(
                recordKeyRef.current,
                partitionKey
            )
        } catch(e: any) {
            console.log("sense page: could not save partition", e)
        }
        setIsRecording(false)
        setIsRecordModalOpen(true)
        setRecordStartTimestamp(0)
    }

    const renderElapsedTime = () => {
        let elapsed = Date.now() - recordStartTimestamp
        let elapsedSeconds = Math.floor(elapsed / 1000)
        let elapsedMinutes = Math.floor(elapsedSeconds / 60)
        let elapsedHours = Math.floor(elapsedMinutes / 60)

        let elapsedMinutesMod = elapsedMinutes % 60
        let elapsedSecondsMod = elapsedSeconds % 60

        let elpasedString = ''
        if (elapsedHours > 0) {
            elpasedString += `${elapsedHours}h `
        }
        if (elapsedMinutes > 0) {
            elpasedString += `${elapsedMinutesMod}m `
        }
        if (elapsedSeconds > 0) {
            elpasedString += `${elapsedSecondsMod}s`
        }
        return elpasedString
    }

    const constructSignatureAndRecognize = (idxStart?: number) => {
        if (idxStart === undefined) {
            idxStart = - DEFAULT_PLOT_DECIMATED_FPS * DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC
        }
        let sectionMZIs = decimatedMZISeriesRef.current.slice(idxStart)
        let sectionMZIsSpans = transpose(sectionMZIs)

        if (!currentSpotsgrid1d) {
            console.log("sense page: spotsgrid is empty")
            return
        }

        // signature with no baseline substraction, simple analyte mean
        let _signature = sectionMZIsSpans.map((mzis) => mean(mzis))
        let excludedSignature: number[] = []
        let excludedSpotsgrid1d: number[] = []
        for (let i = 0; i < currentSpotsgrid1d.length; i++) {
            let sensorInt = currentSpotsgrid1d[i]
            if (sensorInt > 1) {
                excludedSignature.push(_signature[i])
                excludedSpotsgrid1d.push(sensorInt)
            }
        }

        let finalSignature: number[] = []
        let finalSpotsgrid1d: number[] = []

        // always aggregate by common spot name
        let [ aggregatedSignature, aggregatedSpotsgrid1d ] = aggregateSignature(excludedSignature, excludedSpotsgrid1d)
        finalSignature = aggregatedSignature
        finalSpotsgrid1d = aggregatedSpotsgrid1d

        let [ sortedFinaleSignature, sortedFinalSpotsgrid1d ] = sortSignature(finalSpotsgrid1d, finalSignature)
        let normalizedSortedAggregatedSignature = normalizeL2(sortedFinaleSignature)

        setQuestionningSignature(normalizedSortedAggregatedSignature)
        setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d)
    }

    if (!navigatorSupportsSerial()) {
        return <WebserialNotSupportedWidget/>
    }
    
    if (!refkitIsConnected) {
        console.log("sense page: refkit is not connected. Redirecting to connect page")
        return null
    }
    if (isLoading) {
        return <FlexCol style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        }}>
            <Spin size="large"/>
        </FlexCol>
    }

    if (currentSpotsgrid1d === null) {
        return <FlexRow style={{
            width: '100%',
            justifyContent: 'center',
        }}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>
                Spotfile <b>not found</b>. Please go to <b><Link to="/device/serial#spotfile">spotfile management <LinkOutlined /></Link></b> to set it up.
            </>}
        />
        </FlexRow>
    }

    // console.log("sense page: render")

    return <FlexCol style={{
        justifyContent: 'center',
        alignItems: 'start',
        width: '100%',
    }}>{
        isLoading ?
        <Spin size="large"/> :
        <>
            {/* Switch between sensing modes */}
            <FlexRow style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 5
            }}>
                <Radio.Group
                    disabled={isRecording}
                    optionType="button"
                    buttonStyle="solid"
                    size="large"
                    value={senseMode}
                    options={
                        [
                            { label: <><Tooltip overlay="Record samples for odor analysis and model training"><VideoCameraOutlined /> Recording</Tooltip></>, value: SenseMode.Recording },
                            { label: <><Tooltip overlay="Question unknown samples for odor recognition"><QuestionCircleOutlined /> Recognition</Tooltip></>, value: SenseMode.Questionning},
                        ]
                    }
                    onChange={(e) => {
                        setSenseMode(e.target.value)
                    }}
                />
            </FlexRow>
            {/* General parameter controls */}
            <FlexRow style={{
                width: '100%',
                justifyContent: 'end',
                alignItems: 'center',
                gap: 5
            }}>
                <div ref={mziTooltipRef}></div>
                <Tooltip overlay="Toggle debug info">
                    <Button
                        type="text" 
                        size="large" 
                        icon={
                        showDebugInfo ? <BugTwoTone /> : <BugOutlined />}
                        onClick={() => {
                            setShowDebugInfo(!showDebugInfo)
                        }}
                    />
                </Tooltip>
                <Tooltip overlay="Toggle sensor aggregation">
                    <Button 
                        type="text"
                        size="large"
                        onClick={() => {
                            setMziUplotOptions(null)
                            setMziUplotData([])
                            setShouldAggregate(!shouldAggregate)
                            setShouldRedraw(true)
                        }}
                        icon={shouldAggregate ? <BoxPlotTwoTone /> : <BoxPlotOutlined/>}
                    />   
                </Tooltip>
                <Tooltip overlay="Reset to zero">
                    <Button 
                        type="text"
                        size="large"
                        onClick={() => {
                            // previousMZIsRef.current = null is to avoid synthetic phase jumps when signal drops from some value > pi/2 to zero
                            previousMZIsRef.current = null
                            firstMZIsRef.current = null
                        }}
                        icon={<VerticalAlignBottomOutlined />}
                    />   
                </Tooltip>
                <Tooltip overlay="Clear chart">
                    <Button 
                        type="text"
                        size="large"
                        onClick={() => {
                            // setShouldRedraw(true)
                            decimatedMZISeriesRef.current = []
                            decimatedTimestampSeriesRef.current = []
                            
                        }}
                        icon={<ForwardOutlined />}
                    />   
                </Tooltip>
                <Tooltip overlay="Pin last recognition result">
                    <Button 
                        type="text"
                        size="large"
                        onClick={() => {
                            setPinLastQuestionningResult(!pinLastQuestionningResult)
                        }}
                        icon={
                            pinLastQuestionningResult ? <PushpinTwoTone /> : <PushpinOutlined />
                        }
                    />   
                </Tooltip>
            </FlexRow>
            {/* MZI Chart */}
            <Row gutter={[10, 10]} style={{
                width: '100%',
                height: '100%'
            }}>
                <Col xs={24} lg={
                    senseMode === SenseMode.Recording ? 24 : 12
                }>
                    <FlexRow style={{
                        width: '100%',
                        height: '100%',
                        padding: isRecording ? 3 : 0,
                        borderRadius: 10,
                        backgroundColor: isRecording ? 'red' : 'transparent',
                    }}>
                        <Paper style={{
                            width: '100%',
                            height: '100%'
                        }}>
                            <div style={{
                                width: "100%",
                                height: 300,
                                maxHeight: '70vh'
                            }} ref={mziTargetRef}
                            />
                        </Paper>
                    </FlexRow>
                </Col>
                <Col xs={24} lg={12}>
                    {senseMode === SenseMode.Questionning &&
                        <FlexRow style={{
                            width: '100%',
                            height: '100%',
                        }}>
                            <QuestonningResultWidget
                                signature={questionningSignature}
                                spotsgrid1d={questionningSpotsgrid1d}
                            />
                        </FlexRow>
                    }
                </Col>
            </Row>
            {/* Recording controls*/}
            <Row gutter={[5, 5]} style={{ width: '100%'}}>
                <Col xs={24} md={12}>
                    <Button block onClick={() => {
                        setIsSensing(!isSensing)
                        if (refkitPort === null || refkitPort === undefined) {
                            return
                        }
                        refkitProtocolSetMMI(refkitPort, !isSensing).then(() => {
                            console.log("sense page: set refkit mmi to ", !isSensing, "on button click")
                        })
                        
                    }}
                        type={isSensing ? "default" : "primary"}
                    >
                        {isSensing ? <>
                            <LoadingOutlined spin />
                            {" "}Stop sensing
                        </> : "Start sensing"}
                    </Button>
                </Col>
                <Col xs={24} md={12}>
                    { senseMode === SenseMode.Recording &&
                        <>
                            <Button
                                block
                                onClick={() => {
                                    if (isRecording) {
                                        stopRecording()
                                    } else {
                                        startRecording()
                                    }
                                }}
                                type={isRecording ? "default" : "primary"}
                                >{
                                    isRecording ? <>
                                        <LoadingOutlined spin />
                                        {" "}Stop recording
                                    </> : "Start recording"
                                }
                            </Button>
                            <RecordNameModalDialog
                                isOpen={isRecordModalOpen}
                                setIsOpen={setIsRecordModalOpen}
                                recordKey={recordKeyRef.current}
                            />
                        </>
                    }
                    {senseMode === SenseMode.Questionning &&
                        <Tooltip
                            overlayInnerStyle={{
                                textAlign: 'center'
                            }}
                            overlay={<>
                                <span>Takes a few last seconds of signal and tries to recognize the odor</span>
                            </>}
                        >
                            <Button block type="primary" onClick={() => {
                                constructSignatureAndRecognize()
                            }}>Recognize!</Button>
                        </Tooltip>
                    }
                </Col>
            </Row>
            {isRecording &&
                <FlexCol style={{
                    textAlign: 'left',
                    gap: 1,
                }}>
                    <span>🔴 Recording... {renderElapsedTime()}</span>
                    <span><i><b>Note</b>: leaving this page will stop the recording (saving what is recorded so far)</i></span>
                </FlexCol>
            }
            {/* Debug & FPS Chart*/}
            {showDebugInfo &&
                <FlexCol style={{
                    width: '100%',
                }}>
                    <Divider><BugOutlined /></Divider>
                    <FlexCol style={{
                        width: '100%',
                    }}>
                        <Paper style={{
                            width: '100%',
                            height: '100%'
                        }}>
                            <FlexCol>
                                <Typography.Title level={5}>Signal jitter</Typography.Title>
                                <FlexCol style={{
                                    gap: 2,
                                    fontSize: 11,
                                }}>
                                    <span><span style={{color: 'red'}}>Red</span>: raw FPS (as received from the device): <b>{rawFps.toFixed(0)} [{DEFAULT_RAW_FPS}]</b></span>
                                    <span><span style={{color: 'blue'}}>Blue</span>: decimated FPS: <b>{decimatedFps.toFixed(1)} [{DEFAULT_PLOT_DECIMATED_FPS}]</b></span>
                                    <span>Dotted lines: ideal targets (indicated in square brackets above)</span>
                                    {/* <span>Mean K: <b>{KsRef.current ? mean(KsRef.current) : "null"}</b></span> */}
                                </FlexCol>
                            </FlexCol>
                            <div style={{
                                width: "100%",
                                height: 300,
                            }} ref={fpsTargetRef}
                            />
                        </Paper>
                    </FlexCol>
                </FlexCol>
            }
        </>
    }    
    </FlexCol>
}