import { Col, Row, Spin, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { FlexCol, Paper } from "../common";
import { useMessageContext } from "../reducers/messageContext";
import { CsmBleConnectPage } from "./csmBleConnectPage";
import { CSM_PROTOCOL_COMMAND_TYPE, CSMVersionsInfoMap, parseVersionsResponse } from "../serial/protocols/csmProtocol";
import { v4 as uuidv4 } from 'uuid';
import { CsmSpotfileManagementWidget } from "../widgets/csmSpotfileManagementWidget";
import { ApplicationDeviceType, getApplicationDeviceType } from "../utils";
import { CsmSerialConnectPage } from "./csmSerialConnectPage";

export const CsmDevicePage: FC = () => {

    const {
        csmIsConnected,
        csmMessages,
        consumeCSMMessage,
        addCSMCommand,
    } = useMessageContext()

    const [ versionsInfo, setVersionsInfo ] = useState<CSMVersionsInfoMap | null>(null)
    const [ isLoading, setIsLoading ] = useState<boolean>(true)

    useEffect(() => {
        addCSMCommand({
            id: uuidv4().toString(),
            message: {
                CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
            }
        })
        addCSMCommand({
            id: uuidv4().toString(),
            message: {
                CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetVersions,
            }
        })
    }, [])

    useEffect(() => {
        for (let message of csmMessages) {
            let frame = message.message
            if (frame.Type === CSM_PROTOCOL_COMMAND_TYPE.GetVersions) {
                consumeCSMMessage(message.id)
                try {
                    // TODO: response status code is encoded as ASCII
                    // "1" == 0x31 (49)
                    let versioninfo = parseVersionsResponse(frame.Payload.slice(1))
                    setVersionsInfo(versioninfo)
                    setIsLoading(false)
                } catch(e: any) {
                    console.log('error parsing versions response', e.message)
                }
            }
        }
    }, [csmMessages])

    if (!csmIsConnected) {
        switch (getApplicationDeviceType()) {
            case ApplicationDeviceType.CsmBle:
                return <CsmBleConnectPage/>
            case ApplicationDeviceType.CsmSerial:
                return <CsmSerialConnectPage/>
        }
    }

    return <Row gutter={[10, 10]}>
        <Col xs={24}>
            <Paper>
                <FlexCol style={{
                    alignItems: "center",
                    justifyContent: 'center',
                    gap: 3
                }}>
                    <Typography.Title level={3} style={{
                        textAlign: "center"
                    }}
                    >
                        Aryballe <b>CoreSensor Module</b>
                        {/* over <span style={{color: '#0082FC'}}>BLE</span> */}
                    </Typography.Title>
                    {isLoading ? <Spin/> : 
                        versionsInfo !== null &&
                            <FlexCol style={{
                                gap: 1
                            }}>
                                {Object.entries(versionsInfo).map(([key, value]) => {
                                    return <span key={
                                        key
                                    }><b>{key.toUpperCase()}:</b> {value}</span>
                                })}
                            </FlexCol>
                    }
                </FlexCol>
            </Paper>
        </Col>
        <Col xs={24}>
            <CsmSpotfileManagementWidget/>
        </Col>
    </Row>
}