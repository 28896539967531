import { DEFAULT_PURGE_TIMEOUT, DEFAULT_WRITE_LOCK_WAIT_TIMEOUT } from "../constants/commonConstants"

export const writeWithTimeout = async (port: SerialPort, data: Uint8Array, timeout: number): Promise<void> => {
    if (port.writable === null) {
        console.log("port not writable")
        return
    }
    // console.log('about to write: ', data)
    let t = setTimeout(() => {
        throw new Error('timeout while waiting for port to be unlocked')
    }, DEFAULT_WRITE_LOCK_WAIT_TIMEOUT)
    while (port.writable.locked) {
        console.log('waiting for port to be unlocked')
        await new Promise(r => setTimeout(r, 100))
    }
    clearTimeout(t)
    let writer = port.writable.getWriter()
    let nWrote = 0
    while (nWrote < data.length) {
        let t = setTimeout(() => {
            writer.releaseLock()
        }, timeout)
        try {
            let chunk = data.slice(nWrote, nWrote + 64)
            void await writer.ready
            void await writer.write(chunk)
            console.debug('serial port: written bytes: ', chunk.length)
            clearTimeout(t)
            nWrote += 64
        } catch(e: any) {
            if (e.message && e.message.includes("Releasing")) {
                throw new Error('timeout')
            }
            writer.releaseLock()
        }
    }
    writer.releaseLock()
}

export const purgeReadableStream = async (port: SerialPort, initialError: any): Promise<void> => {
    if (initialError.message && initialError.message.includes("port not readable")) {
        // do not purge if the port is not readable
        return
    }
    if (port.readable === null) {
        // do not purge if the port is not readable
        return
    }
    if (initialError.message && initialError.message.includes("timeout")) {
        // do not purge after a timeout
        return
    }
    let reader = port.readable.getReader()
    let t = setTimeout(() => {
        reader.releaseLock()
    }, DEFAULT_PURGE_TIMEOUT)
    try {
        const { value, done } = await reader.read();
        if (done) {
            console.log(`purged ${value ? value.byteLength : 0} bytes until done is true after an error: `, initialError)
            return
        }
        if (value === undefined) {
            console.log(`purged until empty value after an error: `, initialError)
            return
        }
        clearTimeout(t)
        reader.releaseLock()
        console.log(`purged ${value.byteLength} bytes after an error: `, initialError)
        return
    } catch(e: any) {
        if (e.message && e.message.includes("Releasing")) {
            console.log(`purged until timeout after an error: `, initialError)
            return
        }
        console.log(`purge unexpected error: ${e} after the initial error: ${initialError}`)
    }
}