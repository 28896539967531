import { FC, useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { FlexCol, FlexRow } from "../common";
import { JsonView, defaultStyles } from "react-json-view-lite";
import {
  CSMMisoFrame,
  CSM_PROTOCOL_COMMAND_TYPE,
  CSM_PROTOCOL_EVENT_TYPE,
  CSMVersionsInfoMap,
  parseBiosensorsSignalEvent,
  parseEventPayload,
  parseResponsePayload,
  parseVersionsResponse,
} from "../serial/protocols/csmProtocol";
import { useMessageContext } from "../reducers/messageContext";
import { mean } from "../analysis/utils";
import { v4 as uuidv4 } from "uuid";

export const CsmDebugPage: FC = () => {
  const { csmMessages, consumeCSMMessage, addCSMCommand } = useMessageContext();

  const [csmVersions, setCsmVersions] = useState<CSMVersionsInfoMap | null>(null)
  const [misoResponse, setMisoResponse] = useState<CSMMisoFrame | null>(null)
  const [biosensorsAverageSignal, setBiosensorsAverageSignal] = useState<
    number | null
  >(null);

  useEffect(() => {
    for (let message of csmMessages) {
        let frame = message.message
        if (frame.Type < 0x80) {
            consumeCSMMessage(message.id)
            setMisoResponse(frame)
            if (frame.Type === CSM_PROTOCOL_COMMAND_TYPE.GetVersions) {
              let payload = parseResponsePayload(frame.Payload)
              let versions = parseVersionsResponse(payload.Data)
              setCsmVersions(versions)
            }
        } else {
            if (frame.Type === CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent) {
                consumeCSMMessage(message.id)
                try {
                    let eventPayload = parseEventPayload(frame.Payload)
                    let sensogram = parseBiosensorsSignalEvent(eventPayload.Data)
                    let avg = mean(sensogram)
                    setBiosensorsAverageSignal(avg)
                } catch(e: any) {
                    console.log('error parsing biosensors signal event', e.message)
                }
            }
        }
    }
}, [csmMessages])

  return (
    <FlexCol>
      <Typography.Title level={2}>CSM Serial Debug Page</Typography.Title>
      <FlexRow
        style={{
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <FlexCol>
          <Typography.Title level={5}>MISO Response:</Typography.Title>
          {misoResponse && (
            <JsonView data={misoResponse} style={defaultStyles} />
          )}
        </FlexCol>
        <FlexCol>
          <Typography.Title level={5}>AVG:</Typography.Title>
          {biosensorsAverageSignal?.toFixed(10)}
        </FlexCol>
        <FlexCol>
          <Typography.Title level={5}>Versions:</Typography.Title>
          {csmVersions && (
            <JsonView data={csmVersions} style={defaultStyles} />
          )}
        </FlexCol>
      </FlexRow>
      <FlexRow>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
                },
              });
            } catch (e: any) {
              console.log("error writing mosi on csmSerialPort", e.message);
            }
          }}
        >
          Start
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
                },
              });
            } catch (e: any) {
              console.log("error writing mosi on csmSerialPort", e.message);
            }
          }}
        >
          Stop
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetVersions,
                },
              });
            } catch (e: any) {
              console.log("error writing mosi on csmSerialPort", e.message);
            }
          }}
        >
          GetVersions
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.SetReference,
                },
              });
            } catch (e: any) {
              console.log("error writing mosi characteristic", e.message);
            }
          }}
        >
          SetReference
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSignalMap,
                },
              });
            } catch (e: any) {
              console.log("error writing mosi characteristic", e.message);
            }
          }}
        >
          GetBiosensorSignalMap
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetSamplingRate,
                },
              });
            } catch (e: any) {
              console.log("error writing mosi characteristic", e.message);
            }
          }}
        >
          Get Freq
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            let payload = new Uint8Array(1);
            payload[0] = 60;
            try {
              addCSMCommand({
                id: uuidv4().toString(),
                message: {
                  CmdType: CSM_PROTOCOL_COMMAND_TYPE.SetSamplingRate,
                  Payload: payload,
                },
              });
            } catch (e: any) {
              console.log("error writing mosi characteristic", e.message);
            }
          }}
        >
          Set Freq = 60
        </Button>
      </FlexRow>
    </FlexCol>
  );
};
