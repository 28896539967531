import { DFU } from "../../../ref_design_DFU"
import { RefDesignProtocol } from "../../../ref_design_protocol"
import { DEFAULT_READ_TIMEOUT, DEFAULT_WRITE_TIMEOUT, DEFAULT_READ_CALIBRATION_TIMEOUT, DEFAULT_SYNC_READ_EXPECTED_KEY_TIMEOUT, DEFAULT_SERIAL_BUFFER_SIZE } from "../constants/commonConstants"
import { NOA_SERIAL_DEFAULT_BAUD_RATE, DEFAULT_PEAK_MASK_RADIUS, RefkitGetExtensions, REFKIT_LENGTH_N_BYTES, REFKIT_SERIAL_FILTER, RefkitSetExtensions } from "../constants/noaConstants"
import { writeWithTimeout, purgeReadableStream } from "../devices/commonSerialDevice"
import { composeTramNoa, readPayloadWithTimeout } from "../devices/noaDevice"
import { requestPort } from "../utils"

export const requestRefkitPort = async (): Promise<SerialPort | undefined> => {
    return await requestPort(
        NOA_SERIAL_DEFAULT_BAUD_RATE,
        [REFKIT_SERIAL_FILTER]
    )
}

export const detectRefkitPort = async (): Promise<SerialPort | undefined> => {
    if (! ("serial" in navigator)) {
        throw new Error("WebSerial not supported")
    }
    try {
        let ports = await navigator.serial.getPorts()
        ports = ports.filter(p => (
            p.getInfo().usbVendorId === REFKIT_SERIAL_FILTER.usbVendorId &&
            p.getInfo().usbProductId === REFKIT_SERIAL_FILTER.usbProductId
        ))
        if (ports.length == 0) {
            throw new Error("no ports available")
        }
        for (let port of ports) {
            let info = port.getInfo()
            if (info.usbVendorId === REFKIT_SERIAL_FILTER.usbVendorId &&
                info.usbProductId === REFKIT_SERIAL_FILTER.usbProductId
            ) {
                try {
                    await port.open({ baudRate: NOA_SERIAL_DEFAULT_BAUD_RATE, bufferSize: DEFAULT_SERIAL_BUFFER_SIZE })
                } catch {}
                return port
            }
        }
    } catch(e) {
        throw e
    }
}

export const refkitPortExists = async () => {
    let ports = await navigator.serial.getPorts()
    ports = ports.filter(p => (
        p.getInfo().usbVendorId === REFKIT_SERIAL_FILTER.usbVendorId &&
        p.getInfo().usbProductId === REFKIT_SERIAL_FILTER.usbProductId
    ))
    console.log('refkit ports', ports)
    if (ports.length > 0) {
        return true
    }
    return false
}

export const initializeRefkit = async (port: SerialPort): Promise<{
    version: RefDesignProtocol.IVersionResult,
    config: RefDesignProtocol.IConfigData,
    capabilities: RefDesignProtocol.ICapabilitiesResult,
    memoryInfoImage: RefDesignProtocol.IMemoryInfoResult,
    memoryInfoCoreSensor: RefDesignProtocol.IMemoryInfoResult,
}> => {

    // check device type
    console.debug("refkit preflight: checking device type (version)")
    let versionMsg = await _doRefkitSyncProtocolCommand(port, RefDesignProtocol.MasterRoot.create({
        version: {}
    }), 'version', DEFAULT_READ_TIMEOUT)
    if (!versionMsg?.version) {
        throw new Error("refkit dfu preflight: no version")
    }
    console.debug("refkit preflight: device type is", versionMsg.version.deviceType)

    if (versionMsg.version.deviceType === "DFU") {
        console.debug("refkit preflight: device type is DFU; running goapp")
        let tram = _refkitDFUEncodeMsg(DFU.MasterRoot.create({
            goapp: {}
        }))
        void await writeWithTimeout(port, tram, DEFAULT_WRITE_TIMEOUT)
        throw new Error("refkit preflight: goapp() sent: OK")
    }

    // Stop mmi just in case
    // TODO: if mmi are started, we will have hard times getting the version (above)
    console.debug("refkit preflight: stopping mmi")
    void await _doRefkitSyncProtocolCommand(port, RefDesignProtocol.MasterRoot.create({
        stop: {}
    }), 'header', DEFAULT_READ_TIMEOUT)

    // get image memory infos
    console.debug("refkit preflight: getting image memory infos")
    let memoryInfoImageMsg = await _doRefkitSyncProtocolCommand(port, RefDesignProtocol.MasterRoot.create({
        memInfo: {
            bank: RefDesignProtocol.MemoryBank.MemoryImage
        }
    }), 'memoryInfo', DEFAULT_READ_TIMEOUT)
    if (!memoryInfoImageMsg || !memoryInfoImageMsg.memoryInfo) {
        throw new Error("refkit preflight: no image memory info")
    }

    // get core sensor memory infos
    console.debug("refkit preflight: getting core sensor memory infos")
    let memoryInfoCoreSensorMsg = await _doRefkitSyncProtocolCommand(port, RefDesignProtocol.MasterRoot.create({
        memInfo: {
            bank: RefDesignProtocol.MemoryBank.MemoryCoreSensor
        }
    }), 'memoryInfo', DEFAULT_READ_TIMEOUT)
    if (!memoryInfoCoreSensorMsg || !memoryInfoCoreSensorMsg.memoryInfo) {
        throw new Error("refkit preflight: no core sensor memory info")
    }

    // vcsel on
    console.debug("refkit preflight: turning vcsel on")
    void await _doRefkitSyncProtocolCommand(port, RefDesignProtocol.MasterRoot.create({
        vcsel: {
            state: true
        }
    }), 'header', DEFAULT_READ_TIMEOUT)

    // get preconfig to assess if we need to calibrate
    console.debug("refkit preflight: getting config")
    let preConfigMsg = await _doRefkitSyncProtocolCommand(port, RefDesignProtocol.MasterRoot.create({
        config: {}
    }), 'config', DEFAULT_READ_TIMEOUT)
    if (!preConfigMsg || !preConfigMsg.config || !preConfigMsg.config.config) {
        throw new Error("refkit protocol preflight: no config")
    }
    
    // the default exposure on firware startup is 250, so we need to calibrate
    if (preConfigMsg.config.config.cameraExposure === 250) {
        // calibrate exposure
        console.debug("refkit preflight: calibrating exposure")
        void await _doRefkitSyncProtocolCommand(port, RefDesignProtocol.MasterRoot.create({
            calibExpo: {}
        }), 'calibExpo', DEFAULT_READ_CALIBRATION_TIMEOUT)
    } else {
        console.debug("refkit preflight: exposure already calibrated")
    }

    if (
        !preConfigMsg.config.config.peakMasks ||
        preConfigMsg.config.config.peakMasks.length === 0
    ) {
        // calibrate peak mask
        console.debug("refkit preflight: calibrating peak mask")
        void await _doRefkitSyncProtocolCommand(port, RefDesignProtocol.MasterRoot.create({
            calibPeakMask: {}
        }), 'calibPeakMask', DEFAULT_READ_CALIBRATION_TIMEOUT)
    } else {
        console.debug("refkit preflight: peak mask already calibrated")
    }

    // get capabilities
    console.debug("refkit preflight: getting capabilities")
    let capabilitiesMsg = await _doRefkitSyncProtocolCommand(port, RefDesignProtocol.MasterRoot.create({
        capabilities: {}
    }), 'capabilities', DEFAULT_READ_TIMEOUT)
    if (!capabilitiesMsg || !capabilitiesMsg.capabilities) {
        throw new Error("refkit preflight: no capabilities")
    }
    
    // get calibrate config
    console.debug("refkit preflight: getting calibrated config")
    let calibratedConfigMsg = await _doRefkitSyncProtocolCommand(port, RefDesignProtocol.MasterRoot.create({
        config: {}
    }), 'config', DEFAULT_READ_TIMEOUT)
    if (!calibratedConfigMsg || !calibratedConfigMsg.config || !calibratedConfigMsg.config.config) {
        throw new Error("refkit protocol preflight: no calibrated config")
    }

    // set peak mask radius
    console.debug("refkit preflight: setting peak mask radius")
    if (!calibratedConfigMsg.config.config.peakMasks) {
        throw new Error("refkit preflight: no peak masks")
    }
    for (let i = 0; i < calibratedConfigMsg.config.config.peakMasks.length; i++) {
        calibratedConfigMsg.config.config.peakMasks[i].radius = DEFAULT_PEAK_MASK_RADIUS
    }
    // remove peak phasis calib because otherwise the message is too long
    // TODO: find a better way to do this
    calibratedConfigMsg.config.config.peakPhasisCalib = undefined

    // set config
    console.debug("refkit preflight: setting calibrated config", calibratedConfigMsg.config.config)	
    void await _doRefkitSyncProtocolCommand(port, RefDesignProtocol.MasterRoot.create({
        configure: {
            config: calibratedConfigMsg.config.config
        }
    }), 'header', DEFAULT_READ_TIMEOUT)

    return {
        version: versionMsg.version,
        capabilities: capabilitiesMsg.capabilities,
        config: calibratedConfigMsg.config.config,
        memoryInfoImage: memoryInfoImageMsg.memoryInfo,
        memoryInfoCoreSensor: memoryInfoCoreSensorMsg.memoryInfo
    }
}
const _refkitProtocolEncodeMsg = (masterMsg: RefDesignProtocol.MasterRoot): Uint8Array => {
    let payload = RefDesignProtocol.MasterRoot.encode(masterMsg).finish()
    let tram = composeTramNoa(payload, REFKIT_LENGTH_N_BYTES)
    return tram
}
const _refkitDFUEncodeMsg = (dfuMsg: DFU.MasterRoot): Uint8Array => {
    let payload = DFU.MasterRoot.encode(dfuMsg).finish()
    let tram = composeTramNoa(payload, REFKIT_LENGTH_N_BYTES)
    return tram
}
const _doRefkitSyncProtocolCommand = async (
    port: SerialPort,
    masterMsg: RefDesignProtocol.MasterRoot,
    expectedKey: keyof RefDesignProtocol.IResultChannel,
    readTimeout: number
): Promise<RefDesignProtocol.IResultChannel | null | undefined> => {
    const tram = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, tram, DEFAULT_WRITE_TIMEOUT)

    let expectedKeyTimeout = DEFAULT_SYNC_READ_EXPECTED_KEY_TIMEOUT
    if (expectedKeyTimeout < readTimeout) {
        expectedKeyTimeout = readTimeout
    }
    try {
        let isDone = false
        let t = setTimeout(() => {
            isDone = true
        }, expectedKeyTimeout)
        while (!isDone) {
            try {
                let payloadBytes = await readPayloadWithTimeout(port, REFKIT_LENGTH_N_BYTES, readTimeout)
                let slaveMsg = RefDesignProtocol.SlaveRoot.decode(payloadBytes)
                if (slaveMsg.result && slaveMsg.result[expectedKey]) {
                    console.log('refkit sync command: received the expected key', slaveMsg.result[expectedKey])
                    clearTimeout(t)
                    return slaveMsg.result
                } else {
                    console.log(
                        'refkit sync command: received response without the expected key; continuing..', slaveMsg
                    )
                }
            } catch(e) {
                clearTimeout(t)
                throw e
            }
        }
    } catch (e: any) {
        void await purgeReadableStream(port, e)
        throw e
    }
}
const _doRefkitSyncDFUCommand = async (
    port: SerialPort,
    masterMsg: DFU.MasterRoot,
    expectedKey: keyof DFU.IResultChannel,
    readTimeout: number
): Promise<DFU.IResultChannel | null | undefined> => {
    const tram = _refkitDFUEncodeMsg(masterMsg)
    void await writeWithTimeout(port, tram, DEFAULT_WRITE_TIMEOUT)
    let expectedKeyTimeout = DEFAULT_SYNC_READ_EXPECTED_KEY_TIMEOUT
    if (expectedKeyTimeout < readTimeout) {
        expectedKeyTimeout = readTimeout
    }
    try {
        let t = setTimeout(() => {
            throw new Error(`refkit dfu sync command: expected key timeout: ${expectedKey}`)
        }, expectedKeyTimeout)
        while (true) {
            try {
                let payloadBytes = await readPayloadWithTimeout(port, REFKIT_LENGTH_N_BYTES, readTimeout)
                let slaveMsg = DFU.SlaveRoot.decode(payloadBytes)
                if (slaveMsg.result && slaveMsg.result[expectedKey]) {
                    console.log('refkit dfu sync command: received the expected key', slaveMsg.result[expectedKey])
                    clearTimeout(t)
                    return slaveMsg.result
                } else {
                    console.log(
                        'refkit dfu sync command: received response without the expected key; continuing..', slaveMsg
                    )
                }
            } catch(e) {
                clearTimeout(t)
                throw e
            }
        }
    } catch (e: any) {
        void await purgeReadableStream(port, e)
        throw e
    }
}
export const refkitProtocolGetVersion = async (port: SerialPort) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        version: {}
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitDFUGetVersion = async (port: SerialPort) => {
    let masterMsg = DFU.MasterRoot.create({
        version: {}
    })
    let view = _refkitDFUEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitDFUGoapp = async (port: SerialPort) => {
    
    // let version = await refkitDFUGetVersion(port)
    // if (!version) {
    //     throw new Error("refkit dfu goapp preflight: no version")
    // }
    // if (version.deviceType !== "DFU") {
    //     console.log(`refkit dfu goapp preflight: device type is ${version.deviceType}, not DFU; skipping`)
    //     return null
    // }
    let masterMsg = DFU.MasterRoot.create({
        goapp: {}
    })
    let view = _refkitDFUEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolGetConfig = async (port: SerialPort) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        config: {}
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolSetConfig = async (port: SerialPort, config: RefDesignProtocol.IConfigData) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        configure: {
            config
        }
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolGetCapabilities = async (port: SerialPort) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        capabilities: {}
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolSetVCSEL = async (port: SerialPort, state: boolean) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        vcsel: {
            state
        }
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolSetMMI = async (port: SerialPort, state: boolean) => {
    let masterMsg: RefDesignProtocol.MasterRoot
    if (state) {
        masterMsg = RefDesignProtocol.MasterRoot.create({
            start: {
                withAlgo: {
                    withPeaks: true,
                    // withSensorgram: false,
                    // withHistogram: false,
                    // withIq: false
                }
            }
        })
    } else {
        masterMsg = RefDesignProtocol.MasterRoot.create({
            stop: {}
        })
    }
        
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolCalibrateExposure = async (port: SerialPort) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        calibExpo: {}
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refKitProtocolCalibratePeakMask = async (port: SerialPort) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        calibPeakMask: {}
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolSingleShot = async (port: SerialPort) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        singleShot: {
            outputImageEncoding: RefDesignProtocol.ImageEncoding.PIXEL_DEPTH_8_BITS
        }
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

const _refkitProtocolGetMemoryInfo = async (port: SerialPort, bank: RefDesignProtocol.MemoryBank) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        memInfo: {
            bank
        }
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolGetMemoryInfoImage = async (port: SerialPort) => {
    void await _refkitProtocolGetMemoryInfo(port, RefDesignProtocol.MemoryBank.MemoryImage)
}

export const refkitProtocolGetMemoryInfoCoreSensor = async (port: SerialPort) => {
    void await _refkitProtocolGetMemoryInfo(port, RefDesignProtocol.MemoryBank.MemoryCoreSensor)
}

export const refkitProtocolGetMemoryInfoMain = async (port: SerialPort) => {
    void await _refkitProtocolGetMemoryInfo(port, RefDesignProtocol.MemoryBank.MemoryMain)
}

const _refkitProtocolReadMemory = async (port: SerialPort, bank: number, offset: number, size: number) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        memRead: {
            bank,
            offset,
            size
        }
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolReadMemoryImage = async (port: SerialPort, offset: number) => {
    void await _refkitProtocolReadMemory(port, RefDesignProtocol.MemoryBank.MemoryImage, offset, 1024)
}

export const refkitProtocolReadMemoryCoreSensor = async (port: SerialPort, offset: number) => {
    void await _refkitProtocolReadMemory(port, RefDesignProtocol.MemoryBank.MemoryCoreSensor, offset, 1024)
}

export const refkitProtocolReadMemoryMain = async (port: SerialPort, offset: number) => {
    void await _refkitProtocolReadMemory(port, RefDesignProtocol.MemoryBank.MemoryMain, offset, 1024)
}

export const refkitProtocolSetPumpPower = async (port: SerialPort, power: number) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        pump: {
            power
        }
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolSetHIH = async (port: SerialPort, state: boolean) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        hih: {
            state
        }
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolSetValve = async (port: SerialPort, id: number, state: boolean) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        valve: {
            id,
            state
        }
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const _refkitProtocolSetExtension = async (port: SerialPort, key: RefkitSetExtensions, value: RefDesignProtocol.IExtensionValue ) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        setExtension: {
            key,
            value
        }
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const _refkitProtocolGetExtension = async (port: SerialPort, key: RefkitGetExtensions) => {
    let masterMsg = RefDesignProtocol.MasterRoot.create({
        getExtension: {
            key
        }
    })
    let view = _refkitProtocolEncodeMsg(masterMsg)
    void await writeWithTimeout(port, view, DEFAULT_WRITE_TIMEOUT)
}

export const refkitProtocolGetAlgoLogs = async (port: SerialPort) => {
    void await _refkitProtocolGetExtension(port, RefkitGetExtensions.getAlgoLogsFromPB)
}

export const refkitProtocolGetPeakExtractPixelsMap = async (port: SerialPort) => {
    void await _refkitProtocolGetExtension(port, RefkitGetExtensions.getPeakExtractPixelsMap)
}